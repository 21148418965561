<template>
  <div class="blog_bage">
    <a-row gutter="20">
      <a-col :span="4">
        <div class="showing">
          <p class="line-clamp-1"><span>{{totoal_visits}}</span> 用户讨论中</p>
          <p class="line-clamp-1"><span>{{totoal_posts}}</span> 帖子更新中</p>
        </div>
        <div class="blog_hot_post">
          <h5>热门应用
            <span @click="onOneChange"><RedoOutlined />换一批</span>
          </h5>
          <ul>
            <li v-for="(item, index) in app_list" :key="index" @click="toAppDetail(item)">
              <a-space :size="5">
                <span><img :src="item.image_512" alt=""></span>
                <span class="line-clamp-1">{{item.name}}</span>
              </a-space>
            </li>
          </ul>
        </div>
      </a-col>
      <a-col :span="20">
        <div class="info">
          <div class="blog_list" @click.stop="" v-for="item in data_list.records" :key="item.id">
            <router-link :to="{ path: `/forum/section/${item.id}` }">
              <div>
                <h5><img :src="item.image_url" alt="">{{ item.name }}</h5>
                <div>板块摘要</div> 
                <div>{{item.abstract}}</div>
              </div>
              <div class="right" >
                <span>{{item.total_visits}}互动，</span>
                <span>{{item.total_posts}}帖子</span>
              </div>
            </router-link>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-back-top />
  </div>
</template>

<script setup>
import { RedoOutlined } from '@ant-design/icons-vue';
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, reactive, watch } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { message } from "ant-design-vue";
import { trackUV } from "@/utils/trackPage";
import { useRouter } from "vue-router";

const router = useRouter();

const tag_id = ref(0);
const data_list = reactive({
  records: [],
  record_count: 0,
  user_blog_num: 0,
  reply_comment_count: 0,
  comment_count: 0,
  like_count: 0,
  hot_blog: [],
  hot_blog_page_count: 2,
  hot_blog_page_index: 1,
});
const totoal_visits = ref(0);
const totoal_posts = ref(0);
const pageSize = ref(8);
const current = ref(1);
const app_list = ref([]);

const toAppDetail = (item) => {
  router.push({
    path: `/app/${item.id}`,
  });
}

function fetchData() {
  jsonRPC({
    url: `/api/blog/forum/section`,
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取技术论坛成功`, data);
      Object.assign(data_list, data);
      data.records.forEach(item => {  
        totoal_visits.value += item.total_visits;
        totoal_posts.value += item.total_posts;
      });
    },
    fail(error) {
      logError(`获取技术论坛失败`, error);
      message.error(`获取技术论坛失败，请稍后再试`);
    },
  });
  jsonRPC({
    url: "/api/app_list",
    params: {
      state:'All',
      page_index:1,
      page_size:100,
      search:null
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      app_list.value = data.records.slice(0, 10);
    },
    fail(error) {
      logError(`查询失败app, `, error);
    },
  });
}
onMounted(() => {
  fetchData();
  trackUV();
});
watch([tag_id, pageSize, current], fetchData);

const onOneChange = () => {
  if (app_list.value.length > 0) {
    const shuffled = app_list.value.sort(() => 0.5 - Math.random());
    app_list.value = shuffled.slice(0, 10);
  }
};
</script>

<style scoped lang="scss">
.blog_bage {
  padding-top: 20px;
  padding-bottom: 50px;
  padding-left: 25px;
  padding-right: 30px;
  background-color: #f0f0f0;
  .ant-menu{
    border-radius: 8px;
  }
  .showing{
    @mixin span{
      font-size: 36px;
      font-weight: 600;
       // 屏幕宽度小于 768px 时的样式
      @media (max-width: 1200px) {
        font-size: 32px;
      }
    }
    p:nth-child(1){
      span{
        color: #FF3838;
        @include span();
      }
    }
    p:nth-child(2){
      span{
        color: #FFB029;
        @include span();
      }
    }
    p:nth-child(3){
      span{
        color: #FF3838;
      }
    }
    p:nth-child(4){
      span{
        color: #FF3838;
      }
    }
    p:nth-child(5){
      span{
        
      }
    }
  }
  .info{
    // background-color: #fff;
    .blog_title{
      display: flex;
      margin-bottom: 15px;
      .tags {
        flex: 1 1 100%;
        display: flex;
        flex-wrap: wrap;
        .tag {
          color: #000;
          border-radius: 8px;
          padding: 0.3rem 1rem;
          cursor: pointer;
          &.active,
          &:hover {
            background-color: #D5E9FF;
          }
          &:not(:last-child){
            margin-right: 10px;
          }
        }
        .selecters{
          flex: 0 0 auto;
          .ant-select{
            width: 100px; 
            margin-left: 18px;
            :deep(.ant-select-selector) {
              background-color: #C9E0FF;
              color: #006BFF;
            }
            :deep(.ant-select-arrow) {
              color: #006BFF; // 修改箭头符号的颜色为红色
              font-size: 15px;
            }
          }
          
        }
      }
    }
    .blog_list{
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 2px 2px 2px 0px rgba(128, 128, 128, 0.4);
      margin-bottom: 15px;
      padding: 15px 25px;
      position: relative;
      h5{
        font-size: 16px;
        color:#101010;
        img{
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 15px;
        }
      }
      div{
        color:#101010;
      }
      .right{
        position: absolute;
        top: 29px;
        right: 30px;
      }
      .blog_list_top{
        display: flex;
        align-items: center;
        color: #A1A1A1;
        margin-bottom: 5px;
        .user{
          display: flex;
          align-items: center;
          color: #A1A1A1;
          &:hover{
            color: #ff8c00;
          }
        }
        img{
          height: 25px;
          width: 25px;
          margin-right: 5px;
          border-radius: 50%;
        }
      }
      .blog_list_bottom{
        display: flex;
        justify-content: space-around;
        margin-top: 5px;
        img{
          width: 25px;
          height: 25px;
          margin-right: 5px;
        }
      }
    }
  }
  .blog_user{
    border-radius: 8px;
    background-color: #fff;
    padding: 25px;
    margin-bottom: 15px;
    .user_info{
      text-align: center;
      img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-bottom: 1rem;
      }
      h5{
        font-size: 16px;
      }
    }
    .blog_info{
      display: flex;
      justify-content: space-around;
      a{
        display: flex;
        flex-direction: column;
        align-items: center;
        .count{
          font-size: 28px;
          color: #429bff;
        }
        .title{
          color: #000;
        }
      }
    }
  }
  .blog_message{
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    h5{
      font-weight: bold;
      font-size: 16px;
    }
    img{
      width: 25px;
      height: 25px;
    }
    a{
      display: flex;
      gap: 10px;
      padding: 10px 0;
      color: #101010;
      &:hover{
        color: #ff8c00;
      }
      span{
        flex-shrink: 0;
        margin-left: auto;
        .ant-badge-count{
          min-width: 24px;
          height: 24px;
          font-size: 14px;
          line-height: 24px;
          border-radius: 12px;
        }
      }
    }
  }
  .blog_hot_post{
    flex-grow: 1;
    border-radius: 8px;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    li{
      span{
        cursor: pointer;
        &:hover{
          color: #ff8c00;
        }
        img{
          width: 40px;
          height: 40px;
          margin-right: 5px;
        }
      }
    }

    h5{
      font-size: 16px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      span{
        font-size: 14px;
        color: #949494;
        cursor: pointer;
        display: flex;
        gap: 5px;
      }
    }
    ul{
      padding: 0;
      list-style: none;
      li{
        margin: 10px 0;
        a{
          color: #000;
          &:hover{
            color: #ff8c00;
          }
        }
      }
    }
  }
}
</style>
