import { defineStore } from 'pinia';

const blogStore = defineStore('blog', {
  state: () => ({
    blog_id: null,
  }),
  
  actions: {
    setBlogId(id) {
      this.blog_id = id;
    },
    clearBlogId() {
      this.blog_id = null;
    }
  },
  
  getters: {
    getBlogId: (state) => state.blog_id,
  },
});

export default blogStore; 