<template>
  <div class="container news_list">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/">首页</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>新闻资讯</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="info">
      <h2 class="page-title border-bottom pb-3 mb-3">
        新闻资讯
        <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch"/>
      </h2>
      <div class="row border-bottom pb-3 mb-3 mx-0">
        <div class="col-md-1 pl-0">分类：</div>
        <div class="col-md-11 p-0">
          <ul class="nav">
            <li class="mr-3" :class="{ active: categoryId == 0 }">
              <a :class="{ disabled: categoryId == 0 }" @click="categoryId != 0 && onCategory(0)">全部</a>
            </li>
            <li class="mr-3" v-for="rec in categoryIds" :key="rec.id" :class="{ active: categoryId == rec.id }">
              <a :class="{ disabled: categoryId == rec.id }" @click="categoryId != rec.id && onCategory(rec.id)">{{ rec.name }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="news-list">
        <div v-for="row in data_list" :key="row.id" class="row border-bottom py-3 m-0">
          <div class="col-md-3 pl-0">
            <router-link :to="`/news/${row.id}`" class="img_box1">
              <img :src="`/web/image/hw.news/${row.id}/image_512?t=${new Date().getTime()}`" />
            </router-link>
          </div>
          <div class="col-md-9 p-0">
            <div class="list_info">
              <div class="title mb-2">
                <router-link :to="`/news/${row.id}`">{{
                  row.title
                }}</router-link>
              </div>
              <div class="summary">
                <small class="line-clamp-3">{{ row.summary }}</small>
              </div>
              <div class="date" v-if="row.date">
                {{ row.date }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <a-flex justify="center">
        <a-pagination
          v-model:current="current"
          :showSizeChanger="showSizeChanger"
          v-model:page-size="pageSize"
          :total="total"
          :locale="zhCn"
          :pageSizeOptions="pageSizeOptions"
          :hideOnSinglePage="true"
        />
      </a-flex>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { logDebug, logError } from "@/utils/logger";
import { jsonRPC, getResponseData } from "@/utils/http_utils";
import { zhCn } from "@/utils/zhCn";
import { trackUV } from "@/utils/trackPage";

const categoryIds = ref([]);
const categoryId = ref(0);
const data_list = ref([]);
const total = ref(0);
const showSizeChanger = ref(true);
const pageSize = ref(8);
const current = ref(1);
const search = ref("");
const pageSizeOptions = ref(
  Array.from({ length: 4 }, (_, index) =>
    (pageSize.value * (index + 1)).toString()
  )
);
async function fetchData() {
  await jsonRPC({
    url: "/api/news/list",
    params: {
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
      category_id: categoryId.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      categoryIds.value = data.categoryIds;
      data_list.value = data.records;
      total.value = data.record_count;
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}

onMounted(() => {
  fetchData();
  trackUV();
});
watch([pageSize, current, categoryId], fetchData);

const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};
const onCategory = e => {
  categoryId.value = e;
  current.value = 1;
}
</script>

<style scoped lang="scss">
.news_list {
  .info {
    background-color: #fff;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    .page-title {
      font-size: 1.5rem;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
    li{
      a{
        color: #333;
        transition: all 0.2s;
        &:hover{
          color: #ff8c00;
        }
      }
      &.active{
        a{
          color: #fff;
          background-color: #033E80;
          border-radius: 3px;
          padding: 0 0.25rem;
          &.disabled {
            cursor: not-allowed;
            pointer-events: none;
            user-select: none;
            opacity: 0.6;
          }
        }
      }
    }
    .news-list{
      margin-bottom: 1.5rem;
      .list_info{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .title{
          a{
            font-size: 16px;
            color: #000;
            &:hover{
              color: #ff8c00;
            }
          }
        }
        .summary{
          flex-grow: 1;
        }
        .date{
          color: #999;
        }
      }
    }
  }
}
</style>
