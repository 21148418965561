<template>
  <div id="bucket-mount1">
    <div id="bucket-mount">
      <h4>存储桶挂载</h4>
      <div class="bucket-mount-content">
        <div id="mounted_table">
          <a-table bordered :data-source="oss_mounted_list" :columns="columns" :pagination="false">
            <template #bodyCell="{ column, record }">
              <template v-if="column.dataIndex === 'operation'">
                <a-popconfirm
                    v-if="oss_mounted_list.length"
                    title="确定删除?"
                    @confirm="onDelete(record.id)"
                >
                  <a-button danger size="small">删除</a-button>
                </a-popconfirm>
              </template>
            </template>
          </a-table>
          <a-alert type="warning" message="需要新挂载，请先删除已挂载路径" v-if="oss_mounted_list.length"></a-alert>
        </div>
        <div>
          <a-card title="存储桶挂载">
            <!-- 选择桶 -->
            <a-form layout="vertical" ref="formRef" :model="data_list" class="mb-3" :disabled="oss_mounted_list.length">
              <!-- 选择挂载类型 -->
              <a-form-item label="挂载类型" :rules="[{ required: true, message: '请选择挂载类型!' }]">
                <a-radio-group v-model:value="data_list.mount_type" :options="tagsDataOptions" optionType="button"
                               buttonStyle="solid"/>
              </a-form-item>
              <!-- 选择挂载桶 -->
              <a-form-item label="选择挂载桶" name="selectedBucket" :rules="[{ required: true, message: '请选择桶!' }]">
                <a-select
                    v-model:value="data_list.selectedBucket"
                    placeholder="请选择桶"
                    show-search
                    :options="bucketList"
                    :default-active-first-option="false"
                    style="width: 100%;"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                >
                </a-select>
              </a-form-item>
              <!-- 选择挂载文件夹 -->
              <a-form-item v-if="data_list.mount_type === 'bucket_subdir'" label="选择挂载文件夹"
                           name="selectedbucketTree" :rules="[{ required: true, message: '请选择挂载文件夹!' }]">
                <a-select
                    v-model:value="data_list.selectedbucketTree"
                    placeholder="请选择挂载文件夹"
                    show-search
                    :options="bucketTree"
                    :default-active-first-option="false"
                    style="width: 100%;"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                >
                </a-select>
              </a-form-item>
              <!-- 需要到应用中的文件夹 -->
              <a-form-item name="selectOssMountDirList">
                <template #label>
                <span>
                  挂载目标路径
                  <a-popover trigger="hover">
                    <template #content>
                      <a>若不可选，请检查当前应用模板中是否配置可挂载路径</a>
                    </template>
                    <QuestionCircleTwoTone class="help-icon"/>
                  </a-popover>
                </span>
                </template>
                <a-select
                    v-model:value="data_list.selectOssMountDirList"
                    :disabled="!oss_mount_dir_list.length"
                    placeholder="请选择挂载文件夹"
                    show-search
                    :options="oss_mount_dir_list"
                    :default-active-first-option="false"
                    style="width: 100%;"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                >
                </a-select>

              </a-form-item>
            </a-form>
            <a-flex justify="center" align="center" gap="15">
              <a-button type="primary" @click="confirmMount" :loading="loading" :disabled="oss_mounted_list.length">
                确认挂载
              </a-button>
              <a-button v-show="false" @click="cancelMount">取消</a-button>
              <a-button v-show="false" @click.prevent="getBucketData">刷新</a-button>
            </a-flex>
          </a-card>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, reactive, watch} from "vue";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";
import {logDebug, logError} from "@/utils/logger";
import {QuestionCircleTwoTone} from "@ant-design/icons-vue";

const bucketTree = ref([]); // 挂载点树
const bucketPrefix = ref(''); // 当前选择的前缀
const selectedPath = ref(null); // 当前选择的挂载路径

const formRef = ref()
const loading = ref(false)
const columns = [
  {
    title: '已挂载',
    dataIndex: 'bucket_subdir_path',
    width: '20%',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '5%',
  },
]

const onDelete = id => {
  logDebug("删掉的ID为", id)
  oss_mounted_list.value = oss_mounted_list.value.filter(item => item.id !== id);
  confirmUnmount(id)
};

const confirmUnmount = (item) => {
  logDebug("确认卸载、删掉的ID为", item)

  jsonRPC({
    url: "/api/unmount/oss/bucket",
    params: {
      'mounted_id': item,
      'env_id': envId,
    },
    async success(res) {
      const data = getResponseData(res);
      logDebug(`卸载桶成功`, data);
      await window.parent.postMessage(JSON.stringify({
        event: 'restartApplication',
        data: {
          desktop_app_id: Number(envId) + 10000,
        }
      }), "*");
    },
    fail(error) {
      logError(`卸载桶失败`, error);
      message.error(error);
    },
  });
}

const data_list = reactive({
  mount_type: "bucket", // 挂载类型（桶/文件夹）
  selectedBucket: null, // 当前选择的桶
  selectedbucketTree: null, // 当前选择的桶
  selectOssMountDirList: null, // 当前选择的文件夹
  fullPath: "", // 挂载路径
});
const tagsDataOptions = reactive([
  {
    value: 'bucket',
    label: '存储桶',
  },
  {
    value: 'bucket_subdir',
    label: '桶/文件夹',
  },
]);

// 加载数桶详情文件夹数据
const onBucketDirDetail = (mount_type, bucketName) => {
  if (!bucketName) {
    return
  }
  jsonRPC({
    url: "/api/query/bucket/prefix/dir",
    params: {
      'bucket_name': bucketName,
      'prefix': bucketPrefix.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`加载数桶详情文件夹数据成功`, data);
      data_list.selectedbucketTree = null
      if (data && data.folders.length > 0) {
        bucketTree.value = []
        data.folders.forEach((folder, index) => {
          bucketTree.value.push(
              {
                index: index,
                value: folder.Prefix,
                label: folder.Prefix,
              }
          )
        })
      } else {
        bucketTree.value = []
      }
    },
    fail(error) {
      logError(`加载数桶详情文件夹数据失败`, error);
      message.error(error);
    },
  });
}

// 监听桶文件夹的选择
watch([() => data_list.mount_type, () => data_list.selectedBucket], () => {
  if (data_list.mount_type === "bucket_subdir") {
    // 获取挂载点树数据
    onBucketDirDetail(data_list.mount_type, data_list.selectedBucket);
  }
  selectedPath.value = null;
  data_list.fullPath = "";
})
watch([() => data_list.selectedBucket, () => data_list.selectedbucketTree], () => {
  data_list.fullPath = `${data_list.selectedBucket}/${data_list.selectedbucketTree}`;
})

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})
const contentHeight = ref(props.styleSetting.contentStyle.height)

const primal = props.primal;
const data = props.data;
const envId = data['env_id']

function removeTrailingSlash(path) {
  // 判断是否为 null、undefined 或非字符串
  if (path == null || typeof path !== 'string') {
    return path;
  }
  // 判断是否以斜杠结尾并去掉
  if (path.endsWith('/')) {
    return path.slice(0, -1);
  }
  return path;
}

// 确认挂载
const confirmMount = async () => {
  logDebug("确认挂载")
  logDebug(envId)
  logDebug(data_list.mount_type)
  logDebug(data_list.selectedBucket)
  logDebug(removeTrailingSlash(data_list.fullPath))
  formRef.value.validate()
      .then(async () => {
        loading.value = true;
        await jsonRPC({
          url: "/api/mount/oss/bucket",
          params: {
            "mount_type": data_list.mount_type,
            "bucket_name": data_list.selectedBucket,
            "env_id": envId,
            "bucket_subdir_path": removeTrailingSlash(data_list.selectedbucketTree),
            "app_mount_path": removeTrailingSlash(data_list.selectOssMountDirList),
          },
          async success(res) {
            const data = getResponseData(res);
            logDebug(data);
            message.success('挂载成功，服务将重启，请稍等片刻')
            await window.parent.postMessage(JSON.stringify({
              event: 'restartApplication',
              data: {
                desktop_app_id: Number(envId) + 10000,
              }
            }), "*");
          },
          fail(error) {
            logError(`获取自定义桶数据失败`, error);
            message.error(error)
          },
        }).then(() => {
          loading.value = false;
        });
        return
      })
      .catch(error => {
        logError('error', error);
        return
      });
}
// 取消挂载
const cancelMount = () => {
  logDebug("取消挂载")
}

// 桶列表及树形数据示例
const bucketList = ref([]);
const oss_mounted_list = ref([]);
const oss_mount_dir_list = ref([]);
// 获取存储桶数据
const getBucketData = async () => {
  jsonRPC({
    url: "/api/query/user/bucket/list",
    success(res) {
      const data = getResponseData(res);
      logDebug(data);
      bucketList.value = []
      data.user_id_bucket_list.forEach(r => {
        bucketList.value.push({
          value: r.bucket_name,
          label: `ID:${r.key} -名称：${r.bucket_custom_name}`,
        });
      });
    },
    fail(error) {
      logError(`获取自定义桶数据失败`, error);
      message.error(error);
    },
  });
}

// 查询当前APP可用的挂载路径
const queryBaseBucket = async (envId) => {
  logDebug("获取当前实验环境已经挂载的OSS信息");
  logDebug(envId);
  jsonRPC({
    url: "/api/mount/oss/user/base/info",
    params: {
      env_id: envId,
    },
    success(res) {
      const data = getResponseData(res)
      oss_mounted_list.value = data.oss_mounted_list
      oss_mount_dir_list.value = data.oss_mount_dir_list
      logDebug("获取当前实验环境已经挂载的OSS信息", data);
      logDebug(data)
      return true
    },
    fail(error) {
      logError(`获取当前实验环境已经挂载的OSS信息失败`, error)
      message.error(`获取当前实验环境已经挂载的OSS信息失败`, 3);
      return false
    }
  })
}

onMounted(() => {
  logDebug(`TerminalMountBucket onMounted. envId[${envId}]`);
  getBucketData()
  queryBaseBucket(envId)
});

const onSelected = () => {
  logDebug(`TerminalStatus onSelected. data[${JSON.stringify(data)}]`);
  getBucketData();
};

defineExpose({
  primal,
  data,
  onSelected,
});
</script>

<style scoped lang="scss">
#mounted_table {
  margin-bottom: 15px;
}

#bucket-mount1 {
  height: v-bind(contentHeight);
  overflow-y: scroll;
}

#bucket-mount {
  padding: 15px;
  width: 70%;
  height: 100%;
  margin: 0 auto;
}

:where(.ant-radio-group) {
  :where(.ant-radio-button-wrapper) {
    color: #278FFF;
    border: none;
    line-height: 32px;
    border-radius: 16px;
    background-color: #EDEDED;
    margin-right: 10px;

    &:not(:first-child)::before {
      content: none
    }

    &:first-child {
      border-inline-start: none;
      border-start-start-radius: 0;
      border-end-start-radius: 0;
      border-radius: 16px;
    }

    &:last-child {
      border-start-end-radius: 0;
      border-end-end-radius: 0;
      border-radius: 16px;
    }
  }
}
</style>