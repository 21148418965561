import {logDebug, logError} from "@/utils/logger";
import desktopStore from "@/stores/desktop";

const HI168_VUE_VERSION = "v8.0.6"

// 这个是前端的版本，正式上线前，改成上线前的版本。所以，每次上线后进入下一轮开发时，改成下次上线前的正式版本即可。
export const getFrontendVersion = function () {
    return HI168_VUE_VERSION
}

const isElectron = function () {
    const $desktopStore = desktopStore();
    logDebug(`$desktopStore.type[${$desktopStore.type}]`)
    return `${$desktopStore.type}`.trim().toLowerCase() === 'electron';
}

// 这个是桌面端的版本
export const getDesktopVersion = function () {
    if (isElectron()) {
        try {
            if (window.electronAPI && window.electronAPI.sendSyncMessage && window.electronAPI.supportDesktopVersion) {
                const data = JSON.stringify({
                    event: "getDesktopVersion",
                    data: {}
                })
                logDebug(`window.electronAPI.sendSyncMessage, data[${data}]`)
                const info = window.electronAPI.sendSyncMessage(data)
                logDebug(`info[${JSON.stringify(info)}]`)
                // 比如“v1.0.9”
                return info.version
            }
            return undefined
        } catch (e) {
            logError(`getDesktopVersion, window.electronAPI.sendMessage failed, `, e)
            return undefined
        }
    }
    return undefined
}