<template>
  <!--  a-config-provider 用于对Ant Design Vue进行全局配置，如主题等。  -->
  <a-config-provider
      :locale="zhCN"
      :theme="{
          token: {
              colorTextDisabled: 'black',
              colorBgContainerDisabled: 'white',
              controlItemBgActiveDisabled: 'white',
          }
      }">
    <div class="hi168--container">
      <router-view v-slot="{ Component }">
        <transition enter-active-class="enterAnimation" leave-active-class="leaveAnimation">
          <keep-alive :include="cachedComponents" :max="20">
            <component :is="Component"/>
          </keep-alive>
        </transition>
      </router-view>
    </div>
  </a-config-provider>
</template>

<script setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import {onMounted, onUnmounted, ref, watch} from "vue";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {logInfo, logError} from "@/utils/logger";
import coreStore from './stores/core.js';
const $coreStore = coreStore();

import { useRoute } from 'vue-router';
const cachedComponents = ref(['desktop', 'Desktop']);

// 获取当前路由
const route = useRoute();

// 监听路由变化，动态更新缓存组件的 `include` 属性
watch(
  () => route.path, // 监听路由路径变化
  (newPath) => {
    if (newPath === '/console' || newPath === '/login') {
      // 如果当前路由是 /console，清空缓存列表
      cachedComponents.value = [];
    } else {
      // 否则恢复原来的缓存组件列表
      cachedComponents.value = ['desktop', 'Desktop'];
    }
  },
  { immediate: true } // 立即执行一次，确保初始值正确
);

const heartBreakTask = ref(undefined)

onMounted(() => {
  window.addEventListener('mousedown', onMousedown);
  // 每隔一定时间发送心跳包
  heartBreakTask.value = setInterval(sendHeartbeat, 15000); // 每 15 秒发送一次心跳包
})

onUnmounted(() => {
  window.removeEventListener('mousedown', onMousedown);
  if (heartBreakTask.value) {
    // 必须在组件unmounted的时候清理所有定时任务，否则vue并不会自动释放setInterval的后台任务，会导致请求一直在执行。
    clearInterval(heartBreakTask.value);
  }
})

const onMousedown = function () {
  $coreStore.closeContextMenu();
}

function sendHeartbeat() {
  jsonRPC({
    url: "/api/heartbeat",
    params: {
      "heartbeat":1,
    },
    success(res) {
      const data = getResponseData(res);
      logInfo(`发送心跳成功`, data);
    },
    fail(error) {
      logError(`获取失败`, error);
    },
  });
}
</script>

<style scoped>
.leaveAnimation {
  animation: slideOutLeft 0.5s;
}

.enterAnimation {
  animation: fadeIn 0.5s;
}

:global(img) {
  max-width: 100%;
}

:global(video) {
  max-width: 100%;
}
</style>
