<template>
  <div class="ds-context-menu-section" v-show="props.display || display"
       :style="{top: props.position.y + 'px',left: props.position.x + 'px'}">
    <div class="ds-context-menu">
      <ul v-if="props.display">
        <li v-for="item in list" :key="item.id"
            :class="{ disabled: item.disabled, divide: item.type == 'divide', 'list-item': item.type !== 'divide' }"
            @mousedown="onClick(item)">
          <div class="ds-context-menu-item">
            <i v-show="item.type!='divide'" class="icon" :class="item.icon" inert></i>
            <span>{{ item.text }}</span>
          </div>
        </li>
      </ul>
      <ul v-if="!props.display && display">
        <li class="pl-3"> CPU:{{ data_list.cpu_limit }}核</li>
        <li class="pl-3"> 内存:{{ data_list.mem_limit }}G</li>
        <li class="pl-3"> 磁盘:{{ data_list.disk_limit }}G</li>
        <li v-for="item in list" :key="item.id"
            :class="{ disabled: item.disabled, divide: item.type == 'divide', 'list-item': item.type !== 'divide' }"
            @mousedown="onClick(item)">
          <div class="ds-context-menu-item">
            <i v-show="item.type!='divide'" class="icon" :class="item.icon" inert></i>
            <span>{{ item.text }}</span>
          </div>
        </li>
      </ul>
      <a-modal v-model:open="openDeleteModal" title="删除"
               :confirm-loading="confirmDelete" @ok="confirmFormDelete">
        <p>确定删除？ 删除ID：{{ app_id }}
          <template v-if="environments.length>1">
            <br/>
            <span style="color: blue;">以下当前应用关联的所有环境将被全部删除！</span>
          </template>
          <br/>
          <span v-for="(item, index) in environments" :key="item.id">
                        {{ item.name }}<span v-if="index !== environments.length - 1"><br/></span>
                    </span>
        </p>
      </a-modal>
      <a-modal v-model:open="openRenameModal" title="重命名"
               :confirm-loading="confirmRenameLoading" @ok="confirmRename">
        <a-form ref="formRefApp" :model="renameAppForm">
          <a-form-item label="应用名称" name="custom_name"
                       :rules="[{ required: true, message: '请输入应用环境自定义名称' }]">
            <a-input v-model:value="renameAppForm.custom_name"/>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal v-model:open="openBucketModal" title="新建存储桶"
               :confirm-loading="confirmBucketLoading" @ok="confirmBucket">
        <a-form ref="formRefBucket" :model="bucketForm">
          <a-form-item label="名称" name="custom_name"
                       :rules="[{ required: true, message: '请输入要创建的存储桶名称' },{ validator: validateBucketName },]">
            <a-input v-model:value="bucketForm.custom_name" placeholder="请输入名称"/>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal v-model:open="openFolderModal" title="新建文件夹"
               :confirm-loading="confirmFolderLoading" @ok="confirmFolder">
        <a-form ref="formRefFolder" :model="folderForm">
          <a-alert message="请输入要创建的 文件夹 名称（名称中仅可包含英文大小写字母，不得出现中文字符、空格、特殊符号等）"
                   type="warning" show-icon/>
          <a-form-item label="名称" name="custom_name"
                       :rules="[{ required: true, message: '请输入要创建的文件夹名称' },{ validator: validateNoChinese },]">
            <a-input v-model:value="folderForm.custom_name" placeholder="请输入自定义文件夹名称"/>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal v-model:open="openDeleteBucketModal" title="删除存储桶快捷方式"
               :confirm-loading="confirmDeleteBucketLoading" @ok="confirmBucketDelete">
        <p>确定删除？ 删除存储桶快捷方式：{{ bucket_name }}</p>
      </a-modal>
      <a-modal v-model:open="openRenameBucketModal" title="存储桶重命名"
               :confirm-loading="confirmRenameBucketLoading" @ok="confirmRenameBucket">
        <a-form ref="formRefRenameBucket" :model="renameBucketForm">
          <a-form-item label="新名称" name="custom_name"
                       :rules="[{ required: true, message: '请输入存储桶名称' },{ validator: validateBucket }]">
            <a-input v-model:value="renameBucketForm.custom_name" placeholder="请输入名称"/>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script setup>
// 桌面右键菜单
import {
  ref,
  reactive,
  onMounted,
  onUnmounted,
  computed,
  watch,
} from "vue";
import { logDebug, logError } from "@/utils/logger";
// import RouterUtil from '@/utils/RouterUtil.js';
import WidgetUtil from "@/utils/WidgetUtil.js";
import coreStore from "@/stores/core.js";

const $coreStore = coreStore();
import messageStore from "@/stores/message.js";

const $messageStore = messageStore();
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import {message} from "ant-design-vue";

const props = defineProps({
  display: {
    type: Boolean,
    default: false,
  },
  position: {
    type: Object,
    default: () => ({x: 0, y: 0}),
  },
  data: {
    type: Object,
    default: () => ({id: "0"}),
  },
});
const data = reactive({
  iconNew: [
    {
      id: "1",
      text: "新建",
      type: "default",
      disabled: false,
      icon: "",
      linkId: "",
      action: function () {
        icon_new();
      },
    },
  ],
  icon: [
    {
      id: "1",
      text: "打开",
      type: "default",
      disabled: false,
      icon: "",
      linkId: "",
      action: "",
    },
    {
      type: "divide",
    },
    {
      id: "2",
      text: "帮助",
      type: "default",
      disabled: false,
      icon: "help",
      linkId: "9999",
      action: function () {
        icon_action();
      },
    },
  ],
  iconApp: [
    {
      id: "3",
      text: "删除",
      type: "default",
      disabled: false,
      icon: "",
      linkId: "",
      action: function () {
        icon_delete();
      },
    },
    {
      id: "4",
      text: "重命名",
      type: "default",
      disabled: false,
      icon: "rename",
      linkId: "",
      action: function () {
        icon_rename();
      },
    },
    {
      type: "divide",
    },
    {
      id: "5",
      text: "属性",
      type: "default",
      disabled: false,
      icon: "system",
      linkId: "",
      action: function () {
        icon_Set();
      },
    },
  ],
  iconBucket: [
    {
      id: "3",
      text: "删除快捷方式",
      type: "default",
      disabled: false,
      icon: "",
      linkId: "",
      action: function () {
        bucket_delete();
      },
    },
    {
      id: "4",
      text: "重命名",
      type: "default",
      disabled: false,
      icon: "rename",
      linkId: "",
      action: function () {
        bucket_rename();
      },
    },
    {
      type: "divide",
    },
    {
      id: "5",
      text: "帮助",
      type: "default",
      disabled: false,
      icon: "help",
      linkId: "9999",
      action: function () {
        icon_action();
      },
    },
  ],
  wall: [
    {
      id: "1",
      text: "关于",
      type: "default",
      disabled: false,
      icon: "about",
      linkId: "0000",
      action: "",
    },
    {
      id: "2",
      text: "刷新",
      type: "default",
      disabled: false,
      icon: "",
      linkId: "",
      action: () => {
        icon_refresh();
      },
    },
    {
      id: "3",
      text: "新建存储桶",
      type: "default",
      disabled: false,
      icon: "bucket",
      linkId: "",
      action: () => {
        openBucketModal.value = true
      },
    },
    // {
    //   id: "3",
    //   text: "新建文件夹",
    //   type: "default",
    //   disabled: false,
    //   icon: "folderBucket",
    //   linkId: "",
    //   action: () => {
    //     openFolderModal.value = true
    //   },
    // },
    {
      id: "4",
      text: "帮助",
      type: "default",
      disabled: false,
      icon: "help",
      linkId: "9999",
      action: "",
    },
    {
      type: "divide",
    },
    // {
    // 	id: '5',
    // 	text: '锁屏',
    // 	type: 'default',
    // 	icon: 'lock',
    // 	disabled: false,
    // 	linkId: '',
    // 	action: ()=> {
    // 		RouterUtil.quit();
    // 	}
    // },
    {
      id: "6",
      text: "设置",
      type: "default",
      icon: "system",
      disabled: false,
      linkId: "0001",
      action: "",
    },
    {
      id: "7",
      text: WidgetUtil.isFullscreen() ? "退出全屏" : "全屏",
      type: "default",
      icon: "screen",
      disabled: false,
      linkId: "",
      action: function () {
        WidgetUtil.fullScreen();
      },
    },
  ],
});
const openBucketModal = ref(false);
const openFolderModal = ref(false);
const confirmBucketLoading = ref(false);
const confirmFolderLoading = ref(false);
const formRefBucket = ref();
const formRefFolder = ref();
const bucketForm = reactive({
  custom_name: "",
});
const folderForm = reactive({
  custom_name: "",
});
const validateBucketName = (_, value) => {
  // 检查是否符合长度要求（3到10个字符）
  if (value && (value.length < 3 || value.length > 10)) {
    return Promise.reject('桶名长度必须在 3 到 10 个字符之间！');
  }
  return Promise.resolve();
};

const validateNoChinese = (_, value) => {
  // 正则匹配：只允许字母和数字
  const regex = /^[a-zA-Z0-9]+$/;
  // 检查是否符合长度要求（3到10个字符）
  if (value && (value.length < 3 || value.length > 10)) {
    return Promise.reject('桶名长度必须在 3 到 10 个字符之间！');
  }
  // 检查是否包含非字母或数字的字符
  if (value && !regex.test(value)) {
    return Promise.reject('桶名只能包含字母和数字，不允许包含其他字符或中文！');
  }
  return Promise.resolve();
};
const confirmBucket = () => {
  formRefBucket.value
      .validate()
      .then(() => {
        confirmBucketLoading.value = true;
        jsonRPC({
          url: "/api/create/bucket",
          params: {
            custom_bucket_name: bucketForm.custom_name,
          },
          success(res) {
            logDebug(`自定义桶创建成功`, res);
            message.success(`自定义桶创建成功-${bucketForm.custom_name}`);
            icon_refresh()
          },
          fail(error) {
            logError(`自定义桶失败, `, error);
            message.error(error);
          },
        }).then(() => {
          openBucketModal.value = false;
          confirmBucketLoading.value = false;
          formRefBucket.value.resetFields();
        });
        return;
      })
      .catch((error) => {
        logError("error", error);
        return;
      });
};
const confirmFolder = () => {
  formRefFolder.value
      .validate()
      .then(() => {
        confirmFolderLoading.value = true;
        jsonRPC({
          url: "/api/create/bucket",
          params: {
            custom_bucket_name: folderForm.custom_name,
          },
          success(res) {
            logDebug(`自定义桶创建成功`, res);
            message.success(`自定义桶创建成功-${folderForm.custom_name}`);
          },
          fail(error) {
            logError(`自定义桶失败, `, error);
            message.error(error);
          },
        }).then(() => {
          openFolderModal.value = false;
          confirmFolderLoading.value = false;
          formRefFolder.value.resetFields();
        });
        return;
      })
      .catch((error) => {
        logError("error", error);
        return;
      });
};

const renovate = async () => {
  jsonRPC({
    url: "/vue/desktop/environment",
    params: {},
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      for (const app of data.apps) {
        let loading = false;
        if (app.app_env_status != "Running") {
          loading = true;
          setTimeout(() => {
            icon_refresh()
          }, 5000);
        }
        $coreStore.applications = $coreStore.applications.filter((obj) => {
          return obj.id !== Number(app.id) + 10000;
        });
        $coreStore.applications.push({
          id: Number(app.id) + 10000,
          name: app.name,
          page: "desktopEnvironment",
          icon: String(app.image_url),
          width: 1024,
          height: 600,
          link: true,
          abcPage: true,
          loading: loading,
        });
      }
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  }).then(() => {
    jsonRPC({
      url: "/api/query/user/bucket/list",
      params: {},
      success(res) {
        const data = getResponseData(res);
        logDebug(`获取成功`, data);
        const bucketImages = [
          '/hw_frontend/static/img/BucketFolder.png',
          '/hw_frontend/static/img/BucketFolder1.png',
          '/hw_frontend/static/img/BucketFolder2.png'
        ];
        const filteredBuckets = data.user_id_bucket_list.filter(bucket => bucket.show_desktop);
        for (const [index, bucket] of [...filteredBuckets].reverse().entries()) {
          $coreStore.applications = $coreStore.applications.filter((obj) => {
            return obj.id != bucket.bucket_name;
          });
          $coreStore.applications.push({
            id: bucket.bucket_name,
            name: bucket.bucket_custom_name,
            page: "desktopBucket",
            icon: bucketImages[index % 3],
            width: 1024,
            height: 600,
            link: true,
            abcPage: true,
            loading: false,
          });
        }
      },
      fail(error) {
        logError(`查询失败, `, error);
      },
    });
  });
};
const icon_refresh = async () => {
  // 清除所有应用
  $coreStore.applications = [];
  // 延迟 200 毫秒
  await new Promise((resolve) => setTimeout(resolve, 200));
  // 初始化加载桌面应用
  await $coreStore.init();
  // 加载模板应用和存储桶
  await renovate();
};

const icon_action = () => {
  let id = $coreStore.contextMenu.data.id;
  let foundApplication = $coreStore.applications.find((app) => app.id == id);
  let hasTaskWithId9999 = $coreStore.tasks.some((task) => task.id == "9999");
  if (hasTaskWithId9999) {
    logDebug("数组中存在 id 为 9999 的对象。");
    let index = $coreStore.tasks.findIndex((task) => task.id == "9999");
    $coreStore.tasks[index].helpTag = foundApplication.page;
    $coreStore.tasks[index].title = foundApplication.name + "帮助";
    $coreStore.tasks[index].lastDate = new Date();
    $coreStore.tasks.sort((a, b) => {
      return b.lastDate - a.lastDate;
    });
  } else {
    logDebug("数组中不存在 id 为 9999 的对象。");
    $coreStore.tasks.push({
      id: "9999",
      helpTag: isNaN(Number(id)) ? 'abcStorageBucket' : foundApplication.page,
      title: isNaN(Number(id)) ? '存储管理帮助' : foundApplication.name + "帮助",
      icon: "help",
      page: "sys/help",
      link: false,
      width: 1024,
      height: 600,
      resizable: false,
      min: false,
      focus: true,
      date: new Date(),
      lastDate: new Date(),
    });
  }
};
const icon_Set = () => {
  $coreStore.openApplication($coreStore.contextMenu.data.id);
  // 保存数据到 sessionStorage
  if ($coreStore.contextMenu.data.id > 10000) {
    sessionStorage.setItem(
        `setting${$coreStore.contextMenu.data.id - 10000}`,
        $coreStore.contextMenu.data.id
    );
  }
};
const openDeleteModal = ref(false);
const confirmDelete = ref(false);
const app_id = ref(0);
const environments = ref([]);
const environment_id = ref(0);
const icon_delete = () => {
  openDeleteModal.value = true;
  let id = $coreStore.contextMenu.data.id;
  environment_id.value = Number(id) - 10000
  jsonRPC({
    url: "/vue/desktop/app/query",
    params: {
      id: environment_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      app_id.value = data.app_id;
      environments.value = data.environments;
    },
    fail(error) {
      logError(`删除失败, `, error);
    },
  });
};
const confirmFormDelete = async () => {
  confirmDelete.value = true;
  await icon_attribute();
  await jsonRPC({
    url: `/vue/console/app/delete`,
    params: {
      app_id: app_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`删除实验环境成功`, data);
      message.success(`删除实验环境成功`)
      $messageStore.pushMessage({
        title: "删除应用",
        content: `删除应用: ${data}，
          CPU:${data_list.cpu_limit}核 
          内存:${data_list.mem_limit}G 
          磁盘:${data_list.disk_limit}G`,
      });
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  }).then(() => {
    confirmDelete.value = false;
    openDeleteModal.value = false;
    icon_refresh();
  });
};
const display = ref(false);
const data_list = reactive({
  cpu_limit: 0,
  mem_limit: 0,
  disk_limit: 0,
});
const icon_attribute = async () => {
  await jsonRPC({
    url: "/vue/desktop/environment/attribute",
    params: {
      id: environment_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`删除失败, `, error);
    },
  });
};
const openRenameModal = ref(false);
const confirmRenameLoading = ref(false);
const formRefApp = ref();
const renameAppForm = reactive({
  env_id: 0,
  custom_name: "",
});
const icon_rename = () => {
  renameAppForm.env_id = Number($coreStore.contextMenu.data.id) - 10000;
  renameAppForm.custom_name = $coreStore.contextMenu.data.name;
  openRenameModal.value = true;
};
const confirmRename = () => {
  formRefApp.value
      .validate()
      .then(() => {
        confirmRenameLoading.value = true;
        jsonRPC({
          url: "/api/console/experiment/app/env/custom_name/write",
          params: {
            env_id: renameAppForm.env_id,
            custom_name: renameAppForm.custom_name,
          },
          success(res) {
            logDebug(`重命名成功`, res);
            icon_refresh();
          },
          fail(error) {
            logError(`重命名失败, `, error);
          },
        }).then(() => {
          openRenameModal.value = false;
          confirmRenameLoading.value = false;
          formRefApp.value.resetFields();
        });
        return;
      })
      .catch((error) => {
        logError("error", error);
        return;
      });
};
const icon_new = () => {
  $coreStore.tasks.push({
    id: props.data.id,
    title: props.data.name,
    icon: props.data.icon,
    width: props.data.width,
    height: props.data.height,
    page: props.data.page,
    abcPage: props.data.abcPage,
    resizable: true,
    loading: props.data.loading,
    min: false,
    focus: true,
    date: new Date(),
    lastDate: new Date(),
  });
  $coreStore.applicationSort();
};

onMounted(() => {
  window.addEventListener("mousedown", onMousedown);
});
onUnmounted(() => {
  window.removeEventListener("mousedown", onMousedown);
});
const onMousedown = function () {
  display.value = false;
};

watch(props, () => {
  let object = data.wall.filter((t) => t.id == "7")[0];
  object.text = WidgetUtil.isFullscreen() ? "退出全屏" : "全屏";
});

const list = computed(() => {
  if (props.display) {
    if ($coreStore.contextMenu.type === "wall") {
      return data.wall;
    } else {
      if (Number($coreStore.contextMenu.data.id) > 10000) {
        if ($coreStore.tasks.some((app) => app.id == props.data.id)) {
          return data.iconNew.concat(
              data.icon.slice(0, -1).concat(data.iconApp).slice(1)
          );
        } else {
          return data.icon.slice(0, -1).concat(data.iconApp);
        }
      } else if (isNaN(Number($coreStore.contextMenu.data.id))) {
        return data.icon.slice(0, -1).concat(data.iconBucket);
      } else {
        return data.icon;
      }
    }
  } else {
    if (display.value) {
      return [];
    } else {
      return []; // 确保在所有路径上都返回一个值，这里添加一个默认的返回值
    }
  }
});

const onClick = (item) => {
  let data = $coreStore.contextMenu.data;
  if (typeof item.action == "function") {
    item.action(data);
    return;
  }
  if (!item.linkId == "") {
    $coreStore.openApplication(item.linkId);
  } else {
    $coreStore.openApplication(data.id);
  }
};
// 删除存储桶Bucket
const openDeleteBucketModal = ref(false)
const confirmDeleteBucketLoading = ref(false)
const bucket_id = ref('')
const bucket_name = ref('')
const bucket_delete = () => {
  bucket_id.value = $coreStore.contextMenu.data.id;
  bucket_name.value = $coreStore.contextMenu.data.name;
  openDeleteBucketModal.value = true;
};
const confirmBucketDelete = () => {
  confirmDeleteBucketLoading.value = true;
  jsonRPC({
    url: "/api/oss/bucket/delete/desktop/shortcut",
    params: {
      'bucket_name': bucket_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`删除存储桶快捷方式成功`, data);
      icon_refresh();
      message.success(`删除存储桶桌面快捷方式成功`);
    },
    fail(error) {
      logError(`删除存储桶桌面快捷方式失败，请稍后再试`, error);
      message.error(error);
    },
  }).then(() => {
    confirmDeleteBucketLoading.value = false;
    openDeleteBucketModal.value = false;
  });
};
// 存储桶重命名
const validateBucket = (_, value) => {
  // 检查是否符合长度要求（3到10个字符）
  if (value && (value.length < 3 || value.length > 10)) {
    return Promise.reject('桶名长度必须在 3 到 10 个字符之间！');
  }
  return Promise.resolve();
};
const openRenameBucketModal = ref(false);
const confirmRenameBucketLoading = ref(false);
const formRefRenameBucket = ref();
const renameBucketForm = reactive({
  id: 0,
  custom_name: "",
});
const bucket_rename = () => {
  renameBucketForm.id = $coreStore.contextMenu.data.id;
  openRenameBucketModal.value = true;
};
const confirmRenameBucket = () => {
  formRefRenameBucket.value
      .validate()
      .then(() => {
        confirmRenameBucketLoading.value = true;
        jsonRPC({
          url: "/api/rename/oss/bucket",
          params: {
            bucket_name: renameBucketForm.id,
            rename_custom_name: renameBucketForm.custom_name,
          },
          success(res) {
            logDebug(`重命名成功`, res);
            icon_refresh();
          },
          fail(error) {
            logError(`重命名失败, `, error);
          },
        }).then(() => {
          openRenameBucketModal.value = false;
          confirmRenameBucketLoading.value = false;
          formRefRenameBucket.value.resetFields();
        });
        return;
      })
      .catch((error) => {
        logError("error", error);
        return;
      });
};
</script>

<style lang="less" scoped>
.ds-context-menu-section {
  position: fixed;
  z-index: 999;
}

.ds-context-menu {
  width: 9rem;
  text-align: left;
  border-style: solid;
  border-width: 0.1rem;
  box-shadow: 2px 2px 10px rgba(12, 67, 101, 0.8);

  ul {
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    padding: 0.2rem 0;
    margin: 0;
    list-style: none;

    .list-item {
      padding: 0.1rem 0.6rem;
      cursor: default;
      color: #333;

      &:hover {
        background: #4b8de4;
        color: #fff;
      }

      &.disabled {
        color: #999;
      }

      span {
        margin-left: 0.5rem;
      }
    }

    .divide {
      border-top: 1px solid #ccc;
      margin: 5px;
    }

    .icon {
      display: inline-block;
      width: 15px;
      height: 15px;
      background-size: cover;
    }
  }
}

.ds-context-menu-item {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
</style>
