<template>
  <div>
    <h2>欢迎登录 Hi168</h2>
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="账号密码登录">
        <a-form ref="formLoginRef" :model="formState" autocomplete="off" @finish="onLoginFinish" :wrapper-col="{style: {width: '200px'} }">
          <a-form-item label="账户" name="login" :rules="[{ required: true, message: '请输入账户！' }]">
            <a-input v-model:value="formState.login"/>
          </a-form-item>
          <a-form-item label="密码" name="password" :rules="[{ required: true, message: '请输入密码！' }]">
            <a-input-password v-model:value="formState.password"/>
          </a-form-item>
          <a-flex justify="flex-end" align="center" style="margin-bottom: 40px;">
            <a href="#" @click.prevent="forgotPassword">忘记密码？</a>
          </a-flex>
          <a-flex justify="center" align="center">
            <a-button type="primary" html-type="submit" style="margin-bottom: 20px;">登录</a-button>
          </a-flex>
          <a-flex justify="center" align="center">
            <a-button type="primary" @click="register" style="background-color: #D5D5D5;">注册账号</a-button>
          </a-flex>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="手机号登录">
        <a-form ref="formPhoneRef" :model="formState" autocomplete="off" @finish="onPhoneFinish" :wrapper-col="{style: {width: '200px'} }">
          <a-form-item label="手机号" name="login_phone" :rules="[{ required: true, message: '请输入手机号！' },
            { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确！' }]">
            <a-input v-model:value="formState.login_phone"/>
          </a-form-item>
          <a-form-item label="验证码" name="login_auth_code" :rules="[{ required: true, message: '请输入验证码！' }]">
            <div style="display: flex;align-items: stretch;">
              <a-input v-model:value="formState.login_auth_code" style="margin-right: 10px;"/>
              <a-button type="primary" :disabled="isDisabled" @click="getVerificationCode" style="height: auto;width: auto;">
                {{ isDisabled ? `重新获取(${curCount})` : '获取验证码' }}
              </a-button>
            </div>
          </a-form-item>
          <a-flex justify="flex-end" align="center" style="margin-bottom: 40px;">
            <a href="#" @click.prevent="forgotPassword">忘记密码？</a>
          </a-flex>
          <a-flex justify="center" align="center">
            <a-button type="primary" html-type="submit" style="margin-bottom: 20px;">登录</a-button>
          </a-flex>
          <a-flex justify="center" align="center">
            <a-button type="primary" @click="register" style="background-color: #D5D5D5;">注册账号</a-button>
          </a-flex>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="3" tab="扫码登录">
        <a-spin :spinning="isLoading" size="large" tip="二维码加载中...">
          <div id="hw_wechat_login_container">
            <iframe v-show="!isLoading" :src="wechatIframeSrc" frameborder="0"></iframe>
          </div>
        </a-spin>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
const emits = defineEmits(['call-parent-method']);
import {onMounted, onUnmounted, reactive, ref, computed, watch} from 'vue';
import {getFailedMessage, jsonRPC, jsonOwlRPC, getResponseData} from "@/utils/http_utils";
import {useRouter} from "vue-router";
import {routerPush} from "@/utils/router_utils";
import {message} from "ant-design-vue";
import desktopStore from "@/stores/desktop";

const router = useRouter()
const activeKey = ref('1');
const formState = reactive({
  login: '',
  password: '',
  login_phone: '',
  login_auth_code: '',
});

const setDesktopType = function () {
  // TODO-Alain: 对于electron里访问hi168而言，首先进入的是登录页面。
  //  type代表桌面类型：可能取值有 undefined（默认，对应浏览器端，等价于browser）、browser（对应浏览器端）、electron（对应桌面APP端）
  //  这个登录页面必须在hi168中实现成一个单独的页面。在登录页面中判断用户的session是否有效。
  //  如果session无效，就显示登录表单（密码、手机、扫一扫等方式）。如果session有效，就设置$desktopStore.setType('electron')。
  //  在之后的访问中，可以通过$desktopStore.type来判断当前的运行环境，从而控制部分UI的展示方式。
  const $desktopStore = desktopStore();
  $desktopStore.setType('electron')
  logDebug(`desktop type[${$desktopStore.type}]`)
  setTimeout(()=>{
    window.location.reload()
  },1000)
}
const forgotPassword = ()=>{
  emits('call-parent-method',{
    component: 'reset',
  });
}
const register = ()=>{
  emits('call-parent-method',{
    component: 'signup',
  });
}
const curCount = ref(120);
const timer = ref(null);
const isDisabled = ref(false);
const getVerificationCode = ()=>{
  formPhoneRef.value.validateFields(['login_phone'])
  .then(() => {
    // 验证通过后执行获取验证码的逻辑
    jsonOwlRPC({
      url: "/login/send_auth_code",
      params: {
        mobile: formState.login_phone,
      },
      success(res) {
        logDebug(`验证码获取成功`, res)
        isDisabled.value = true;
        curCount.value = 120;
        timer.value = setInterval(SetRemainTime, 1000);
      },
      fail(error) {
        logError(`验证码获取失败, `, error)
        message.error(`验证码获取失败，[${getFailedMessage(error)}]`);
      }
    })
  })
  .catch((error) => {
    // 验证失败时处理逻辑
    logDebug('手机号验证未通过:', error);
  });
}
const SetRemainTime = () => {
  if (curCount.value === 0) {
    clearInterval(timer.value);
    timer.value = null;
    isDisabled.value = false;
  } else {
    curCount.value--;
  }
};

// onFinish 是前端校验成功的回调，通常用于表单验证。
const formLoginRef = ref();
const onLoginFinish = values => {
  logDebug('Success:', values);
  formLoginRef.value.validate()
  .then(() => {
    jsonRPC({
      url: "/hw_web/login",
      params: {
        login: formState.login,
        password: formState.password,
      },
      success(res) {
        logDebug(`登录成功`, res)
        setDesktopType()
        routerPush(router, "/desktop")
      },
      fail(error) {
        logError(`登录失败, `, error)
        message.error(`登录失败，[${getFailedMessage(error)}]`);
      }
    })
  })
  .catch(error => {
    logDebug('error', error);
  });
}
const formPhoneRef = ref();
const onPhoneFinish = values => {
  logDebug('Success:', values);
  formPhoneRef.value.validate()
  .then(() => {
    jsonRPC({
      url: "/hw_web/login/phone",
      params: {
        login_phone: formState.login_phone,
        login_auth_code: formState.login_auth_code,
      },
      success(res) {
        logDebug(`手机号登录成功`, res)
        setDesktopType()
        routerPush(router, "/desktop")
      },
      fail(error) {
        logError(`手机号登录失败, `, error)
        message.error(`手机号登录失败，[${getFailedMessage(error)}]`);
      }
    })
  })
  .catch(error => {
    logDebug('error', error);
  });
}

const checkLogin = () => {
  jsonRPC({
    url: "/hw_web/validate_session",
    params: {},
    success(res) {
      logDebug(`validate_session success, `, res);
      setDesktopType()
      routerPush(router, "/desktop")
    },
    fail(error) {
      logError(`validate_session error, `, error);
    },
  });
};
const fetchWechatData = () => {
  jsonRPC({
    url: "/hw_web/login/wechat",
    params: {
      'client': 'electron',
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取基本信息成功`, data);
      Object.assign(wechatState, data);
    },
    fail(error) {
      logError(`获取失败, `, error)
    }
  })
};
const wechatState = reactive({
  wechat_login_appid: '',
  wechat_login_redirect_uri: '',
  wechat_login_state: '',
  wechat_login_style: '',
  wechat_login_href: '',
});
const wechatIframeSrc = computed(() => {
  return `https://open.weixin.qq.com/connect/qrconnect?appid=${wechatState.wechat_login_appid}&styletype=
            &scope=snsapi_login&redirect_uri=${wechatState.wechat_login_redirect_uri}&sizetype=
            &state=${wechatState.wechat_login_state}&bgcolor=
            &login_type=jssdk
            &self_redirect=false
            &style=${wechatState.wechat_login_style}&rst=
            &href=${wechatState.wechat_login_href}`;
});
// 加载状态
const isLoading = ref(true);
// 监听 wechatState 的变化
watch(
  () => wechatState.wechat_login_appid,
  (newVal) => {
    if (newVal !== '') {
      // 延迟 1 秒后停止加载
      setTimeout(() => {
        isLoading.value = false;
      }, 1000);
    }
  }
);
onMounted(() => {
  checkLogin()
  fetchWechatData();
  setTimeout(()=>{
    isLoading.value = false;
  },15*1000)
})
onUnmounted(() => {
  if (timer.value) {
    clearInterval(timer.value);
  }
});
</script>

<style scoped lang="scss">
h2{
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
button{
  height: 40px;
  width: 150px;
}
#hw_wechat_login_container{
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  >iframe{
    width: 235px;
    height: 270px;
    overflow: hidden;
  }
}
:deep(.ant-tabs-top >.ant-tabs-nav::before){
  content: none;
}
:deep(.ant-tabs-top >.ant-tabs-nav .ant-tabs-ink-bar){
  height: 3px;
  background-color: #BDD9FF;
}
:deep(.ant-tabs .ant-tabs-tab-btn){
  font-size: 18px;
}
</style>