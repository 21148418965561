<template>
  <div id="manual">
    <a-select
        v-model:value="manualValue"
        :size="'large'"
        style="width: 100%; margin: 0; padding: 0"
        :options="manualOptions"
    />
    <a-space :style="spinStyle">
      <a-typography-text strong>用户手册正在加载中，请耐心等待几秒钟......</a-typography-text>
      <a-spin class="spin" size="large"/>
    </a-space>

    <div id="manual-content" :style="manualContentStyle">
      <div v-for="item in manualOptions"
           :key="item.key"
           class="manual-editor-container"
           :id="`manual-editor-container-${item.key}`"
           style="display: block">
      </div>
    </div>
  </div>
</template>
<script setup>

import {onMounted, ref, watch} from 'vue';
import {logDebug, logError} from "@/utils/logger";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";
import {isFalse, isNotNullOrUndefined, isTrue} from "@/utils/common_utils";
import $ from 'jquery'
import {loadJavaScriptIfNotExist, loadStyleIfNotExist} from "@/utils/load_utils";

logDebug('TerminalManual setup!')

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})

const contentHeight = ref(props.styleSetting.contentStyle.height)

const primal = props.primal
const data = props.data


const manualValue = ref();

const manualOptions = ref([]);

const manualContentStyle = ref('display: none')
const spinStyle = ref('display: none')


const manualDomHolder = {}
const manualScrollHolder = {}


onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalManual onMounted.`)
})

const copySetting = function () {
  $('pre').hover(function () {
    // 鼠标移入
    $(this).css('position', 'relative');
    const copyBtn = $('<button class="btn btn-secondary px-1">复制</button>').css({
      'position': 'absolute',
      'top': '10px',
      'right': '4px'
    });
    $(this).append(copyBtn);

    copyBtn.click(function () {
      // 点击复制按钮
      const code = $(this).parent().find('code').text();
      const temp = $('<textarea>');
      $('body').append(temp);
      temp.val(code).select();
      document.execCommand('copy');
      temp.remove();
      $(this).text('复制完成');
      setTimeout(function () {
        copyBtn.text('复制');
      }, 3000);
    });

  }, function () {
    // 鼠标移出
    $(this).find('button').remove();
    $(this).css('position', 'static');
  });
}

const render_manual = async function (manual) {
  if (!window.wangEditor) {
    await loadStyleIfNotExist("/hw_experiment/static/src/lib/wangeditor/css/style.css")
    await loadJavaScriptIfNotExist("/hw_experiment/static/src/lib/wangeditor/index.js")
  }

  //富文本编辑器展示内容
  const {createEditor} = window.wangEditor

  function setEditorContent(manual) {
    const manual_id = manual.id
    const content = manual.content
    const editor_container_id = `manual-editor-container-${manual_id}`
    const editor = createEditor({
      selector: `#${editor_container_id}`,
      html: content,
      config: {},
      mode: 'simple',
    })
    editor.disable()
  }

  // 富文本框加载
  setEditorContent(manual)
  copySetting()
}


const watchHandler = function () {
  logDebug(`manualValue[${manualValue.value}]`)

  const manualId = manualValue.value

  // 当wangEditor的富文本编辑器内容非常多时，会严重影响DOM的渲染性能，
  // 所以对于暂时无需展示的用户手册，先用$(manualDom).detach()方法将其从document对象中剥离出来。
  // 之后在需要展示时，再将其重新添加到document对象中。
  // 另外，由于在剥离和重新添加manualDom对象过程中，用户手册滚动的位置信息会被丢失掉，
  // 所以还需要用manualScrollHolder对象来保存manualDom对象被剥离前的滚动条位置。
  for (const option of manualOptions.value) {
    // option.contentStyle = 'display: none'
    const manualDomId = `manual-editor-container-${option.key}`
    const manualDom = document.getElementById(manualDomId)
    if (isNotNullOrUndefined(manualDom)) {
      // manualDom.style.display = 'none'
      const scroll = document.querySelector(`#${manualDomId} .w-e-scroll`)
      if (isNotNullOrUndefined(scroll)) {
        manualScrollHolder[manualDomId] = scroll.scrollTop;
      } else {
        manualScrollHolder[manualDomId] = 0;
      }
      manualDomHolder[manualDomId] = $(manualDom).detach()
    }
  }

  manualContentStyle.value = 'display: none'
  spinStyle.value = 'display: block'

  for (const option of manualOptions.value) {
    if (option.key === manualId) {
      // 如果是已经加载过数据的用户手册，就直接从manualDomHolder中将其取出来，然后添加到document对象上，将其展示出来
      if (isTrue(option.contentLoaded)) {
        spinStyle.value = 'display: none'
        manualContentStyle.value = 'display: block'
        const manualContent = document.getElementById('manual-content')
        const manualDomId = `manual-editor-container-${option.key}`
        const manualDom = manualDomHolder[manualDomId]
        delete manualDomHolder[manualDomId]
        $(manualContent).append(manualDom)
        const scroll = document.querySelector(`#${manualDomId} .w-e-scroll`)
        const manualScroll = manualScrollHolder[manualDomId]
        delete manualScrollHolder[manualDomId]
        if (isNotNullOrUndefined(manualScroll)) {
          scroll.scrollTop = manualScroll;
        } else {
          scroll.scrollTop = 0;
        }
        return
      }
    }
  }

  const manualContent = document.getElementById('manual-content')
  const manualDomId = `manual-editor-container-${manualId}`
  const manualDom = manualDomHolder[manualDomId]
  $(manualContent).append(manualDom)

  const getManualResult = jsonRPC({
    url: "/api/manual",
    params: {
      manual_id: manualId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('getTerminalSettingResult', data)
      render_manual(data)

      for (const option of manualOptions.value) {
        if (option.key === manualId) {
          spinStyle.value = 'display: none'
          manualContentStyle.value = 'display: block'
          option.contentLoaded = true
        }
      }
    },
    fail(error) {
      logError(`获取用户手册失败, `, error)
      message.error(`获取用户手册失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  getManualResult.then(function (res) {
    logDebug(`getManualResult, `, res)
  })

}


watch(manualValue, function () {
  setTimeout(watchHandler, 300)
})

const isFirstSelected = ref(true)

const onSelected = function () {
  logDebug(`TerminalManual onSelected. data[${JSON.stringify(data)}]`)
  if (isFalse(isFirstSelected.value)) {
    return
  }

  const options = []
  for (const item of data) {
    options.push({
      value: item.id,
      label: item.name,
      key: item.id,
      contentStyle: "display: none",
      contentLoaded: false,
    })
  }
  manualOptions.value = options
  manualValue.value = manualOptions.value[0].value

  isFirstSelected.value = false
}

defineExpose({
  primal,
  data,
  onSelected,
})

</script>
<style scoped>
#manual {
  background: white;
  width: 100%;
  height: v-bind(contentHeight);
}

#manual-content {
  background: white;
  width: 100%;
  height: v-bind(contentHeight);
}

.manual-editor-container {
  width: 100%;
  height: v-bind(contentHeight);
}

.spin {
  margin-top: 10vh;
  width: 100%;
  height: 40vh
}
</style>

