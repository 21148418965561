<template>
  <div class="container news_detail">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/">首页</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link to="/news">新闻资讯</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.title }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="info">
      <div class="border-bottom text-center pb-3 mb-3">
        <h2 class="page-title mb-3">{{ data_list.title }}</h2>
        <div v-if="data_list.date">{{ data_list.date }}</div>
      </div>
      <div class="text-center mb-3">
        <img :src="`/web/image/hw.news/${news_id}/image_512?t=${new Date().getTime()}`"/>
      </div>
      <div v-html="data_list.content"></div>
    </div>
  </div>
</template>

<script setup>
import { logDebug, logError } from "@/utils/logger";
import { onMounted, ref, reactive } from "vue";
import { getResponseData, jsonRPC } from "@/utils/http_utils";
import { getRouterParam } from "@/utils/router_utils";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { trackUV } from "@/utils/trackPage";

const router = useRouter();
const news_id = ref(getRouterParam(router, "id"));

const data_list = reactive({});
function fetchData() {
  jsonRPC({
    url: `/api/news/detail`,
    params: {
      news_id: news_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取新闻详情成功`, data);
      Object.assign(data_list, data);
    },
    fail(error) {
      logError(`获取新闻详情失败`, error);
      message.error(`获取新闻详情失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
  trackUV();
});
</script>

<style scoped lang="scss">
.news_detail {
  .info {
    background-color: #fff;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    .page-title {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
}
</style>
