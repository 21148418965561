<template>
  <div>
    <div class="application-section scope">
      <Window v-for="app in apps" :key="app.id" :setting="app"></Window>
    </div>
    <div class="taskbar-section scope">
      <div class="dog-start">
        <div :style="{ backgroundImage: `url(${backgroundUrl})` }" class="dog-icons" @click="onStart"
             @mouseleave="handleMouseLeave" @mouseover="handleMouseOver"></div>
      </div>
      <div class="task-list">
        <div v-for="task in tasklist" :key="task.id" :class="{ actived: task.focus }"
             :style="{ width: itemWidth + 'px' }"
             :title="task.title" class="task-item"
             @click="onClick(task.id,task.lastDate)" @contextmenu.prevent="onRightClick($event, task.id)">
          <span v-if="!task.icon.includes('image') && !task.icon.includes('img')" :class="task.icon" class="icon"></span>
          <span v-else :style="`background-image: url(${task.icon})`" class="icon"></span>
          {{ task.title.slice(0, 4) }}
        </div>
        <div v-if="showContextMenu" :style="{ left: contextMenuX + 'px' }" class="ds-context-menu-section">
          <div class="ds-context-menu">
            <ul>
              <li class="list-item" @mousedown="initializeTask">
                <div class="ds-context-menu-item">
                  <i class="icon" inert>
                    <RedoOutlined/>
                  </i>
                  <span>初始化</span>
                </div>
              </li>
              <li class="list-item" @mousedown="closeTask">
                <div class="ds-context-menu-item">
                  <i class="icon" inert>
                    <CloseOutlined/>
                  </i>
                  <span>关闭</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="dog-tool">
        <!-- <a-tooltip placement="top" title="设置" :color="'blue'" :get-popup-container="getPopupContainer">
          <div class="dog-task-icon" @click="onDisplay">
            <DsIcon icon="setting" :width="20" :height="20"></DsIcon>
          </div>
        </a-tooltip> -->
        <a-tooltip v-if="isElectron && supportCloudLink" :color="'blue'" :get-popup-container="getPopupContainer"
                   placement="top" title="云端互联">
          <div class="dog-task-icon" @click="onCloudLink">
            <img src="@/assets/icon/cloud.png" height="20" width="20">
          </div>
        </a-tooltip>
        <a-tooltip :color="'blue'" :get-popup-container="getPopupContainer" placement="top" title="直播列表">
          <div class="dog-task-icon" @click="onLive">
            <WechatOutlined style="font-size: 20px;"/>
          </div>
        </a-tooltip>
        <a-tooltip :color="'blue'" :get-popup-container="getPopupContainer" placement="top" title="消息通知">
          <div class="dog-task-icon" @click="onSidebar">
            <DsIcon :height="20" :width="20" icon="bubble"></DsIcon>
          </div>
        </a-tooltip>
        <a-tooltip v-if="isElectron"
                   :color="'blue'" :get-popup-container="getPopupContainer" placement="top" title="刷新桌面">
          <div class="dog-task-icon" @click="doRefreshDesktop">
            <DsIcon :height="20" :width="20" icon="screen"></DsIcon>
          </div>
        </a-tooltip>
        <a-tooltip v-if="!isElectron"
                   :color="'blue'" :get-popup-container="getPopupContainer" placement="top" title="返回控制台">
          <div class="dog-task-icon" @click="ReturnToConsole">
            <DsIcon :height="20" :width="20" icon="screen"></DsIcon>
          </div>
        </a-tooltip>
        <a-tooltip :color="'blue'" :get-popup-container="getPopupContainer" placement="topRight" title="返回桌面">
          <div class="dog-task-icon" @click="ReturnDesktop">
            <div style="line-height: 20px;">
              <MoreOutlined/>
            </div>
          </div>
        </a-tooltip>
      </div>
      <transition enter-active-class="enterAnimation" leave-active-class="leaveAnimation">
        <div v-show="showmenu" class="dog-menu">
          <div class="dog-menu-list dogstar-scroll">
            <ul>
              <li v-for="item in data.menulist" :key="item.id">
                <div class="menu-item" @click="onClickItem(item)">
                  <i :class="item.icon" class="icon"></i>
                  <span>{{ item.name }}</span>
                </div>
                <ul v-if="item.sublist" v-show="!item.subhidden">
                  <li v-for="sub in item.sublist" :key="sub.id">
                    <div class="menu-item" style="margin-left: 20px ;text-align:left"
                         @click="onClickItem(sub)">
                      <i :class="sub.icon" class="icon"></i>
                      <span>{{ sub.name }}</span>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="dog-menu-links">
            <ul>
              <li><a href="#" @click.prevent="onLink($event,'0000')"><span>关于</span></a></li>
              <li><a href="#" @click.prevent="onLink($event,'9999')"><span>使用帮助</span></a></li>
              <li><a href="#" @click.prevent="onLink($event,'0003')"><span>个人信息</span></a></li>
              <li><a href="#" @click.prevent="onLink($event,'8014')"><span>费用中心</span></a></li>
              <li><a href="#" @click.prevent="onLink($event,'0001')"><span>设置</span></a></li>
              <li v-if="!isElectron">
                <router-link to="/console"><span>切换控制台</span></router-link>
              </li>
              <li v-if="isElectron">
                <a href="#" @click.prevent="doRefreshDesktop"><span>刷新桌面</span></a>
                <a href="#" @click.prevent="doRefreshDesktopIgnoringCache"><span>强制刷新</span></a>
              </li>
              <li><a href="#" @click.prevent="doLogoutDesktop"><span>退出登录</span></a></li>
            </ul>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import {logDebug, logError} from "@/utils/logger";
import {CloseOutlined, MoreOutlined, RedoOutlined, WechatOutlined} from '@ant-design/icons-vue';
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import DsIcon from '@/components/DsIcon.vue'
// import RouterUtil from '@/utils/RouterUtil.js';
import Window from './Window.vue';
import REGISTER from '@/register/index.js'
import coreStore from '@/stores/core.js';
import router from '@/router';
import desktopStore from "@/stores/desktop";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";

const $coreStore = coreStore();
const data = reactive({
  menulist: []
});

const showContextMenu = ref(false);
const contextMenuX = ref(0);
const selectedTask = ref(null);

const isElectron = computed(() => {
  const $desktopStore = desktopStore();
  logDebug(`$desktopStore.type[${$desktopStore.type}]`)
  return `${$desktopStore.type}`.trim().toLowerCase() === 'electron'
})

const supportCloudLink = ref(
    !!(window.electronAPI && window.electronAPI.sendSyncMessage && window.electronAPI.supportCloudLink)
)

const doLogoutDesktop = function () {
  jsonRPC({
    url: "/hw_web/logout",
    params: {},
    success(res) {
      logDebug(`退出登录成功，res[${res}]`)
      if (!isElectron.value) {
        router.push("/login")
        return
      }
      router.replace('/login_desktop');
      $coreStore.selectIcon('');
      $coreStore.switchSidebar(false);
      $coreStore.switchLivebar(false);
    },
    fail(error) {
      logError(`退出登录失败`, error);
      message.error(`退出登录失败`);
    },
  });
}

const doRefreshDesktop = function () {
  if (isElectron.value) {
    if (window.electronAPI && window.electronAPI.sendMessage) {
      const data = JSON.stringify({
        event: "reloadDesktop",
        data: {}
      })
      logDebug(`window.electronAPI.sendMessage, data[${data}]`)
      window.electronAPI.sendMessage(data)
    } else {
      window.location.reload()
    }
  } else {
    window.location.reload()
  }
}

const doRefreshDesktopIgnoringCache = function () {
  if (isElectron.value) {
    if (window.electronAPI && window.electronAPI.sendMessage) {
      const data = JSON.stringify({
        event: "reloadDesktopIgnoringCache",
        data: {}
      })
      logDebug(`window.electronAPI.sendMessage, data[${data}]`)
      window.electronAPI.sendMessage(data)
    } else {
      window.location.reload(true)
    }
  } else {
    window.location.reload(true)
  }
}


const onRightClick = (event, task) => {
  let taskItem = event.target.closest('.task-item');
  let taskItemRect = taskItem.getBoundingClientRect();
  contextMenuX.value = taskItemRect.left - 11;
  showContextMenu.value = true;
  selectedTask.value = task;
};

const initializeTask = () => {
  closeTask()
  setTimeout(() => {
    let keyToRemove = 'tasks' + selectedTask.value
    sessionStorage.removeItem(keyToRemove);
    $coreStore.openApplication(selectedTask.value);
  }, 500)
};

const closeTask = () => {
  showContextMenu.value = false;
  $coreStore.closeApplication(selectedTask.value);
};

const handleClickOutside = (event) => {
  if (!event.target.closest('.context-menu')) {
    showContextMenu.value = false;
  }
};
const getPopupContainer = trigger => {
  return trigger.parentElement;
};
const Christmas = '/hw_frontend/static/img/Christmas.png';
const SpringFestival = '/hw_frontend/static/img/SpringFestival.png';
let defaultBackground = new URL('@/assets/icon/cat6.png', import.meta.url).href;
let hoverBackground = new URL('@/assets/icon/cat5.png', import.meta.url).href;
// 定义动态背景地址
const backgroundUrl = ref(defaultBackground);
// 处理鼠标事件
const handleMouseOver = () => {
  backgroundUrl.value = hoverBackground;
};
const handleMouseLeave = () => {
  backgroundUrl.value = defaultBackground;
};
onMounted(() => {
  jsonRPC({
    url: "/vue/home/layout",
    params: {},
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取layout数据成功`, data)
      if (data.is_Christmas) {
        defaultBackground = Christmas;
        hoverBackground = Christmas;
        backgroundUrl.value = Christmas;
      } else if (data.is_SpringFestival) {
        defaultBackground = SpringFestival;
        hoverBackground = SpringFestival;
        backgroundUrl.value = SpringFestival;
      }
    },
    fail(error) {
      logError(`获取失败`, error);
    },
  });
  REGISTER.application.forEach(item => {
    let object = {};
    if (item.sublist) {
      object.sublist = [];
      item.sublist.forEach(o => {
        let sub = {};
        sub.id = o.id;
        sub.name = o.name;
        sub.icon = o.icon;
        object.sublist.push(sub);
      });
      object.subhidden = true;
    }
    object.id = item.id;
    object.name = item.name;
    object.icon = item.icon;
    data.menulist.push(object);
  });
  const storedObject = sessionStorage.getItem("tasks");
  if (storedObject) {
    const myObject = JSON.parse(storedObject);
    for (let iterator of myObject) {
      iterator.date = new Date(iterator.date)
      iterator.lastDate = new Date(iterator.lastDate)
    }
    $coreStore.tasks = myObject
  }
  document.addEventListener('click', handleClickOutside);
});
onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
const apps = computed(() => {
  let apps = [];
  if ($coreStore.tasks.length > 0) {
    $coreStore.tasks.forEach(app => {
      if (app != null) {
        apps.push(app);
      }
    });
    return apps.sort((a, b) => {
      return a.date - b.date;
    });
  } else {
    return [];
  }
});
const tasklist = computed(() => {
  let tasks = [];
  $coreStore.tasks.forEach((task, index) => {
    if (task != null) {
      if (index == 0 && task.min == false) {
        task.focus = true
      } else {
        task.focus = false
      }
      tasks.push(task);
    }
  });
  return tasks.sort((a, b) => {
    return a.date - b.date
  });
});
watch(tasklist, () => {
  sessionStorage.setItem("tasks", JSON.stringify($coreStore.tasks));
});
const itemWidth = computed(() => {
  const taskList = document.querySelector('.task-list');
  if (taskList) {
    let ww = taskList.offsetWidth;
    return Math.floor(ww / $coreStore.tasks.length);
  } else {
    let ww = document.body.clientWidth * 0.9;
    return Math.floor(ww / $coreStore.tasks.length);
  }
});

const showmenu = computed(() => {
  return $coreStore.startMenu;
});

const onClickItem = function (object) {
  if (object.sublist) {
    object.subhidden = !object.subhidden;
  } else {
    $coreStore.openApplication(object.id);
    $coreStore.selectIcon('');
  }
};
const onLink = async function (event, id) {
  event.preventDefault();
  if (id == '8014') {
    await $coreStore.closeApplication('8014');
    await sessionStorage.setItem('openCost', true);
  }
  await $coreStore.openApplication(id);
  $coreStore.selectIcon('');
};
const onClick = function (id, lastDate) {
  $coreStore.applicationMinSwitch(id, lastDate);
};
const onStart = function () {
  $coreStore.openMenu();
};
const onSidebar = function () {
  $coreStore.switchLivebar(false);
  $coreStore.switchSidebar();
};
const onLive = function () {
  $coreStore.switchSidebar(false);
  $coreStore.switchLivebar();
};
// const onDisplay = function () {
//   $coreStore.openApplication('0001');
// }
const onCloudLink = function () {
  $coreStore.openApplication('0004');
}
const ReturnToConsole = function () {
  logDebug("返回控制台")
  document.onselectstart = null;
  $coreStore.switchSidebar(false);
  $coreStore.switchLivebar(false);
  router.push('/console')
  $coreStore.closeApplication('8016');
  $coreStore.applications.forEach(item => {
    if (isNaN(Number(item.id))) {
      $coreStore.closeApplication(item.id);
    }
  })
  // 清除 缓存
}
const ReturnDesktop = function () {
  $coreStore.tasks.forEach(app => {
    app.focus = false;
    app.min = true;
  });
}
// const lockScreen = function () {
//   RouterUtil.quit();
// }
</script>

<style lang="less" scoped>
.taskbar-section {
  width: 100%;
  height: var(--task-bar-heihgt, 1rem);
  background: var(--theme-color, rgba(0, 170, 255, 0.5));
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.4);
  position: fixed;
  bottom: 0;
  top: auto !important;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dog-start {
  width: 5%;
  min-width: 60px;
  margin: 0 auto;
  height: var(--task-bar-heihgt, 1rem);
}

.dog-tool {
  width: 200px;
  min-width: 40px;
  height: var(--task-bar-heihgt, 1rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dog-task-icon {
  height: 100%;
  padding: 10px 10px;
  display: inline-block;
}

.dog-task-icon:hover {
  background-color: #cfe3fd;
}

.ds-context-menu-section {
  position: absolute;
  top: -63px;
  z-index: 999;
}

.ds-context-menu {
  width: 9rem;
  text-align: left;
  border-style: solid;
  border-width: 0.1rem;
  box-shadow: 2px 2px 10px rgba(12, 67, 101, 0.8);

  ul {
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    padding: 0.2rem 0;
    margin: 0;
    list-style: none;

    .list-item {
      padding: 0.1rem 0.6rem;
      cursor: default;
      color: #333;

      &:hover {
        background: #4b8de4;
        color: #fff;
      }

      &.disabled {
        color: #999;
      }

      span {
        margin-left: 0.5rem;
      }
    }

    .divide {
      border-top: 1px solid #ccc;
      margin: 5px;
    }

    .icon {
      display: inline-block;
      width: 15px;
      height: 15px;
      background-size: cover;

      .anticon {
        display: block;
        margin: 0;
        margin-top: 1px;
      }
    }
  }
}

.ds-context-menu-item {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.task-list {
  flex: 1;
  min-width: 80%; //calc(100% - 100px);
  overflow: hidden;
  height: var(--task-bar-heihgt, 1rem);
  cursor: default;

  .task-item {
    float: left;
    height: var(--task-bar-heihgt, 1rem);
    max-width: 120px;
    min-width: 20px;
    padding: 0 20px 0 15px;
    text-align: center;
    line-height: var(--task-bar-heihgt, 1rem);
    font-size: 14px;
    border-right: 1px solid #e9e9e9;
    color: rgba(255, 255, 255, 0.8);
    overflow: hidden;

    &.actived {
      color: rgba(17, 17, 17, 1);
      background: rgba(255, 255, 255, 1);
    }

    .icon {
      display: inline-block;
      width: 25px;
      height: 25px;
      margin-right: 5px;
      background-size: cover;
      vertical-align: top;
      position: relative;
      top: 7px;
    }
  }
}

.dog-icons {
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 4px;
  list-style: none;
  display: inline-block;
  position: relative;
  font-size: 1.2em;
  // background-image: url(@/assets/icon/cat6.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 265ms ease-out;
}

// .dog-icons:hover {
//   background-image: url(@/assets/icon/cat5.png);
// }

.dog-icons:active {
  animation: heartBeat 1s;
}

.dog-menu {
  bottom: var(--task-bar-heihgt, 1rem);
  left: 0px;
  overflow: visible;
  display: inline-block;
  min-width: 450px;
  border-radius: 5px;
  position: fixed;
  box-shadow: 0 0 10px rgba(12, 67, 101, 0.8);
  opacity: 0.9;
  background: var(--theme-color);

  ul {
    list-style: none;
  }

  .icon {
    display: inline-block;
    height: 18px;
    width: 18px;
    background-size: cover;
    margin-right: 5px;
  }
}

.dog-menu-list {
  width: 50%;
  float: left;
  background: #fff;
  border: solid 1px #365167;
  margin: 7px 0 7px 7px;
  box-shadow: 0 0 1px #fff;
  -moz-box-shadow: 0 0 1px #fff;
  -webkit-box-shadow: 0 0 1px #fff;
  max-height: 400px;
  overflow-y: auto;

  .menu-item {
    border: solid 1px transparent;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 3px;
    margin: 3px;
    color: #4b4b4b;
    font-size: 1.0rem;
  }

  .menu-item:hover {
    border: solid 1px #7da2ce;
    border-radius: 3px;
    box-shadow: inset 0 0 1px #fff;
    background-color: #cfe3fd;
  }

  a img {
    border: 0;
    vertical-align: middle;
    margin: 0 5px 0 0;
  }
}

.dog-menu-title {
  font-size: 1.2rem;
  text-align: center;
}

.dog-menu-links {
  width: 45%;
  float: right;
  margin: 5px;

  li.icon {
    text-align: center;
  }

  a {
    border: solid 1px transparent;
    display: block;
    margin: 5px 0;
    position: relative;
    color: #fff;
    text-decoration: none;
    min-width: 120px;
  }

  a:hover {
    border: solid 1px #7da2ce;
    border-radius: 3px;
    box-shadow: inset 0 0 1px #fff;
    background-color: rgba(207, 227, 253, 0.7);
  }

  a span {
    padding: 5px;
    display: block;
  }
}

.leaveAnimation {
  animation: slideOutLeft 0.9s;
}

.enterAnimation {
  animation: slideInLeft 0.3s;
}
</style>