<template>
  <div class="container abcApps_detail">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <span style="cursor: pointer;" @click="callParentMethod">应用中心</span>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-card>
      <a-row :gutter="[16,16]">
        <a-col :span="12" class="text-center">
          <a-image :src="data_list.image_512+`?t=${new Date().getTime()}`" style="max-height: 240px;"/>
        </a-col>
        <a-col :span="12">
          <h1 class="mb-3">{{ data_list.name }}</h1>
          <a-space>
            <p class="mb-2">当前使用：{{ data_list.used_nums }}次</p>
            <p class="mb-2">查看：{{ data_list.view_nums }}次</p>
            <p class="mb-2">好评：{{ data_list.like_count }}次</p>
            <p class="mb-2">好评度：{{ data_list.satisfaction_rating }}%</p>
          </a-space>
          <!-- <a-space>
            <div>CPU：<span class="text-muted">{{ data_list.cpu_limit }} 核</span></div>
            <div>内存：<span class="text-muted">{{ data_list.mem_limit }} GB</span></div>
            <div>磁盘：<span class="text-muted">{{ data_list.disk_limit }} GB</span></div>
          </a-space> -->
          <p class="mb-3">推荐指数：<a-rate :value="5" :disabled="true"/></p>
          <a class="btn btn-primary" @click="_onCreateDeployment(data_list.id)">部署应用</a>
          <a-modal v-model:open="openCreateAppModal"
                    :title="config_compute_power?'配置算力':'创建应用'" width="60%">
            <a-form ref="formRefApp" :model="createAppForm" :rules="rules" @submit="confirmCreateApp"
                    :label-col="labelCol" :wrapper-col="wrapperCol" layout="horizontal">
              <template v-if="config_compute_power">
                <a-form-item label="算力服务" name="compute_power_service">
                  <a-radio-group v-model:value="createAppForm.compute_power_service" button-style="solid">
                    <a-radio-button v-if="createAppForm.capacity_type_level.base?.length>0" value="base">初级</a-radio-button>
                    <a-radio-button v-if="createAppForm.capacity_type_level.middle?.length>0" value="middle">中级</a-radio-button>
                    <a-radio-button v-if="createAppForm.capacity_type_level.high?.length>0" value="high">高级</a-radio-button>
                  </a-radio-group>
                </a-form-item>
                <div>
                  服务配置：
                  <ul v-if="createAppForm.compute_power_service">
                    <li v-for="item in createAppForm.capacity_type_level[createAppForm.compute_power_service]" :key="item">
                      <a-flex justify="space-between" align="center">
                        <div>
                          <div>{{item.template_inner_service_name}}:</div>
                          <a-flex :gap="15">
                            <span v-for="op in item.capacity.capacity_type_op" :key="op">
                              {{ op.hardware_id.hardware_type }}: {{ op.use_number }}
                              <template v-if="op.hardware_id.hardware_unit">{{op.hardware_id.hardware_unit}}</template><br/>
                              <template v-if="op.hardware_id.hardware_name">({{op.hardware_id.hardware_name}})</template>
                            </span>
                          </a-flex>
                        </div>
                        <div style="color: #a0c8ff;flex-shrink: 0;">{{item.capacity.capacity_type_op_sum_point}} 算力/{{displayUnit(item.capacity.unit_op)}}</div>
                      </a-flex>
                    </li>
                  </ul>
                </div>
                <div style="border-top: 3px solid #2580fd;"></div>
                <a-flex justify="space-between" align="center" class="my-3">
                  <div style="font-size: 16px;">以上配置当前环境算力点总计：</div>
                  <span style="color: #FF5F5F;font-size: 16px;">{{sum_point}} 算力/{{sum_point_unit}}</span>
                </a-flex>
                <a-form-item label="停止时间" name="stop_time">
                  <a-select v-model:value="createAppForm.stop_time" :allowClear="true">
                    <a-select-option value="0">无限制</a-select-option>
                    <a-select-option value="2">2小时后</a-select-option>
                    <a-select-option value="8">8小时后</a-select-option>
                    <a-select-option value="12">12小时后</a-select-option>
                    <a-select-option value="24">24小时后</a-select-option>
                  </a-select>
                </a-form-item>
              </template>
              <template v-else>
                <a-form-item label="应用名称" name="app_name">
                  <a-input v-model:value="createAppForm.app_name"/>
                </a-form-item>
                <a-form-item label="空间类型" name="ns_type">
                  <a-select v-model:value="createAppForm.ns_type" :disabled="createAppForm.disable_namespace_selection">
                    <a-select-option v-for="item in createAppForm.ns_types" :key="item[0]" :value="item[0]">
                      {{ item[1] }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="命名空间" name="namespace_id">
                  <a-select v-model:value="createAppForm.namespace_id"
                            :disabled="createAppForm.disable_namespace_selection">
                    <template v-if="createAppForm.ns_type == 'private'">
                      <a-select-option v-for="item in createAppForm.private_namespaces" :key="item.id" :value="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </template>
                    <a-select-option v-else v-for="item in createAppForm.public_namespaces" :key="item.id"
                                      :value="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="部署方式" name="deployment_method">
                  <a-select v-model:value="createAppForm.deployment_method">
                    <a-select-option value="consumer_compute_power">消耗算力点先用后结</a-select-option>
                    <!-- <a-select-option value="use_resource">使用现有资源</a-select-option> -->
                  </a-select>
                </a-form-item>
                <template v-if="createAppForm.deployment_method=='consumer_compute_power'">
                  <a-form-item label="算力服务" name="compute_power_service">
                    <a-radio-group v-model:value="createAppForm.compute_power_service" button-style="solid">
                      <a-radio-button v-if="createAppForm.capacity_type_level.base?.length>0" value="base">初级</a-radio-button>
                      <a-radio-button v-if="createAppForm.capacity_type_level.middle?.length>0" value="middle">中级</a-radio-button>
                      <a-radio-button v-if="createAppForm.capacity_type_level.high?.length>0" value="high">高级</a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                  <div>
                    服务配置：
                    <ul v-if="createAppForm.compute_power_service">
                      <li v-for="item in createAppForm.capacity_type_level[createAppForm.compute_power_service]" :key="item">
                        <a-flex justify="space-between" align="center">
                          <div>
                            <div>{{item.template_inner_service_name}}:</div>
                            <a-flex :gap="15">
                              <span v-for="op in item.capacity.capacity_type_op" :key="op">
                                {{ op.hardware_id.hardware_type }}: {{op.use_number}}
                                <template v-if="op.hardware_id.hardware_unit">{{op.hardware_id.hardware_unit}}</template><br/>
                                <template v-if="op.hardware_id.hardware_name">({{op.hardware_id.hardware_name}})</template>
                              </span>
                            </a-flex>
                          </div>
                          <div style="color: #a0c8ff;flex-shrink: 0;">{{item.capacity.capacity_type_op_sum_point}} 算力/{{displayUnit(item.capacity.unit_op)}}</div>
                        </a-flex>
                      </li>
                    </ul>
                  </div>
                  <div style="border-top: 3px solid #2580fd;"></div>
                  <a-flex justify="space-between" align="center" class="my-3">
                    <div style="font-size: 16px;">以上配置当前环境算力点总计：</div>
                    <span style="color: #FF5F5F;font-size: 16px;">{{sum_point}} 算力/{{sum_point_unit}}</span>
                  </a-flex>
                </template>
              </template>
            </a-form>
            <template #footer>
              <a-flex justify="center" align="center">
                <a-button type="primary" :loading="confirmCreateAppLoading" @click="confirmCreateApp">
                  {{config_compute_power?'开始使用':createAppForm.deployment_method=='use_resource'?'配置算力':'确认'}}
                </a-button>
                <a-button @click="onCloseCreateAppModal">取消</a-button>
              </a-flex>
            </template>
          </a-modal>
        </a-col>
      </a-row>
    </a-card>
    <a-spin :spinning="cardSpinning">
      <a-card
          :bordered="false"
          style="width: 100%"
          :tab-list="tabListNoTitle"
          :active-tab-key="noTitleKey"
          @tabChange="key => onTabChange(key, 'noTitleKey',data_list.id)"
      >
        <div v-if="noTitleKey === 'app_description'">
          <div v-if="data_list.description" v-html="data_list.description"></div>
          <div v-if="data_list.description_html" v-html="data_list.description_html"></div>
        </div>
        <div v-else-if="noTitleKey === 'app_help'">暂无帮助文件</div>
        <div v-else-if="noTitleKey === 'app_comment'">
          <a-row>
            <a-space>
              <p>好评度：{{ data_list.satisfaction_rating }}%</p>
              <p>全部评价：({{ data_list.comment_total }})</p>
              <p>好评：({{ data_list.like_count }})</p>
              <p>中评：({{ data_list.neutral_count }})</p>
              <p>差评：({{ data_list.dis_like_count }})</p>
            </a-space>
          </a-row>
          <!--评论框-->
          <a-form
              ref="formRef"
              :model="formState"
              name="basic"
              autocomplete="off">
            <a-form-item name="rate" label="评分">
              <a-rate v-model:value="formState.rate"/>
              <span class="ant-rate-text">{{ formState.rate }}分</span>
            </a-form-item>
            <a-form-item label="评论内容" name="comment"
                          :rules="[{ required: true, message: '请输入评论内容' }]">
              <a-textarea placeholder="请输入评论内容" v-model:value="formState.comment"
                          :auto-size="{ minRows: 2, maxRows: 5 }"/>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 10, span: 16 }">
              <a-button type="primary" @click="sendAppComment(data_list.id)">提交</a-button>
            </a-form-item>
          </a-form>
          <!--展示评论内容-->
          <div class="comment" id="comment" v-for="comment_rec in commentData" :key="comment_rec.id">
            <a-card :loading="cardLoading" v-if="comment_rec.comment_id === false">
              <!--父评论-->
              <a-comment>
                <template #actions>
                  <span key="comment-basic-reply-to" @click="openModalData(comment_rec)">回复</span>
                  <a-popconfirm title="确定要删除?" v-if="comment_rec.create_uid[0]==userId" @confirm="onCommentDelete(comment_rec.id)">
                    <span key="comment-basic-reply-to">删除</span>
                  </a-popconfirm>
                </template>
                <a-modal v-model:open="openModal" :title="'回复:' + openSubCommentData.id" :mask="false">
                  <a-form name="basic" autocomplete="off">
                    <a-form-item label="评论内容">
                      <a-textarea placeholder="请输入评论内容" v-model:value="formStateReplay.comment"
                                  :auto-size="{ minRows: 2, maxRows: 5 }"/>
                    </a-form-item>
                  </a-form>
                  <template #footer>
                    <a-button key="back" @click="openModal = false;">取消</a-button>
                    <a-button key="submit" type="primary" :loading="confirm_submit_loading"
                              @click="sendSubAppComment(openSubCommentData)">提交
                    </a-button>
                  </template>
                </a-modal>
                <template #author>
                  <a>{{ comment_rec.id }}</a>-
                  <a>{{ comment_rec.create_uid[1] }}</a>
                </template>
                <template #avatar>
                  <a-avatar
                      :src="'/web/image?model=res.users&id=' + comment_rec.create_uid[0] + '&field=avatar_128'"/>
                </template>
                <template #content>
                  <a-typography-paragraph>
                    {{ comment_rec.comment }}
                  </a-typography-paragraph>
                </template>
                <!--子评论-->
                <div class="sub_comment" id="sub_comment" v-for="sub in subCommentData"
                      :key="sub.id">
                  <a-comment v-if="sub.comment_id[0] === comment_rec.id">
                    <template #actions>
                      <a-popconfirm title="确定要删除?" v-if="sub.create_uid[0]==userId" @confirm="onCommentDelete(sub.id)">
                        <span key="comment-basic-reply-to">删除</span>
                      </a-popconfirm>
                    </template>
                    <template #author>
                      <a>{{ sub.create_uid[1] }}</a>
                    </template>
                    <template #avatar>
                      <a-avatar
                          :src="'/web/image?model=res.users&id=' + sub.create_uid[0] + '&field=avatar_128'"/>
                    </template>
                    <template #content>
                      <a-typography-paragraph>
                        {{ sub.comment }}
                      </a-typography-paragraph>
                    </template>
                  </a-comment>
                </div>
              </a-comment>
            </a-card>
          </div>
          <!--评论的分页-->
          <a-flex justify="center" class="py-3">
            <a-pagination
              v-model:current="commentPageCurrent"
              :showSizeChanger="commentShowSizeChanger"
              v-model:pageSize="commentPageSize"
              :total="commentTotal"
              :locale="zhCn"
              :pageSizeOptions="commentPageSizeOptions"
              :hideOnSinglePage="true"/>
          </a-flex>
        </div>
      </a-card>
    </a-spin>
  </div>
</template>

<script setup>
import { getRouterParam } from "@/utils/router_utils";
import { useRouter } from "vue-router";
const router = useRouter();
const app_id = ref(getRouterParam(router, "app_id"));
function callParentMethod() {
  router.push('/app')
}
import {logDebug, logError} from "@/utils/logger";
import {onMounted, reactive, ref, watch, computed} from "vue";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {zhCn} from "@/utils/zhCn";
import {message,Modal} from 'ant-design-vue';

const openCreateAppModal = ref(false);
const confirmCreateAppLoading = ref(false);
const createAppForm = reactive({
  app_name: "",
  ns_type: "",
  namespace_id: "",
  app_id: "",
  template_id: "",
  capacity_type_level: {},
  deployment_method: 'consumer_compute_power',
  compute_power_service: 'base',
  stop_time: null,
  disable_namespace_selection: false,
  templates: [],
});
const _onCreateDeployment = (template_id) => {
  jsonRPC({
    url: "/hw_web/validate_session",
    params: {},
    success(res) {
      logDebug(`validate_session success, `, res);
      createAppForm.template_id = template_id
      createAppForm.disable_namespace_selection = false
      if (createAppForm['ns_types'] != null && typeof createAppForm['ns_types'] !== "undefined" &&
          createAppForm['ns_types'].length >= 1) {
        createAppForm.ns_type = createAppForm['ns_types'][0][0]
      }
      if (createAppForm.ns_type === 'private') {
        if (createAppForm['private_namespaces'] != null && typeof createAppForm['private_namespaces'] !== "undefined" &&
            createAppForm['private_namespaces'].length >= 1) {
          createAppForm.namespace_id = createAppForm['private_namespaces'][0].id
        }
      } else {
        if (createAppForm['public_namespaces'] != null && typeof createAppForm['public_namespaces'] !== "undefined" &&
            createAppForm['public_namespaces'].length >= 1) {
          createAppForm.namespace_id = createAppForm['public_namespaces'][0].id
        }
      }
      openCreateAppModal.value = true;
    },
    fail(error) {
      logError(`validate_session error, `, error);
      router.push('/login')
    },
  });
};
const formRefApp = ref()
const rules = {
  app_name: [
    {
      required: true,
      message: '请输入应用名称',
    },
  ],
  ns_type: [
    {
      required: true,
      message: '请选择空间类型',
      trigger: 'change',
    },
  ],
  namespace_id: [
    {
      required: true,
      message: '请选择命名空间',
      trigger: 'change',
    },
  ],
  deployment_method: [
    {
      required: true,
      message: '部署方式',
      trigger: 'change',
    },
  ],
  compute_power_service: [
    {
      required: true,
      message: '算力服务',
    },
  ],
  stop_time: [
    {
      required: true,
      message: '停止时间',
      trigger: 'change',
    },
  ],
}

const config_compute_power = ref(false)
const sum_point = computed(() => {
  let sum = 0;
  const ser = createAppForm.compute_power_service;
  for (const x of createAppForm.capacity_type_level[ser] || []) {
    sum += x.capacity.capacity_type_op_sum_point;
  }
  return sum;
});
const unitMap = {
  hours: '小时',
  day: '天',
  month: '月'
};
const displayUnit = (e) => {
  return unitMap[e] || '小时';
}
const sum_point_unit = computed(() => {
  let sum = '小时';
  const ser = createAppForm.compute_power_service;
  for (const x of createAppForm.capacity_type_level[ser] || []) {
    if (unitMap[x.capacity.unit_op]) {
      sum = unitMap[x.capacity.unit_op];
      break;
    }
  }
  return sum;
});
const confirmCreateApp = () => {
  if (createAppForm.deployment_method=='use_resource' && !config_compute_power.value) {
    config_compute_power.value = true;
    return
  }
  formRefApp.value.validate()
  .then(() => {
    confirmCreateAppLoading.value = true;
    jsonRPC({
      url: "/vue/console/experiment/deployment/create",
      params: {
        name: createAppForm.app_name,
        template_id: createAppForm.template_id,
        namespace_id: createAppForm.namespace_id,
        is_compute_point: sum_point.value > 0,
        capacity_type_level: createAppForm.compute_power_service,
        need_deduct_compute_point: sum_point.value,
        capacity_unit_option: createAppForm.capacity_type_level[createAppForm.compute_power_service]?.[0]?.capacity?.unit_op,
      },
      success(res) {
        const data = getResponseData(res)
        logDebug(`创建实验环境成功`, data);
        message.success(`创建实验环境成功`)
        router.push('/desktop')
        setTimeout(()=>{
          jsonRPC({
            url: "/vue/template/used_nums",
            params: {
              template_id: createAppForm.template_id,
            },
            success() {
              logDebug(`模板使用次数增加成功`);
            },
            fail(error) {
              logError(`模板使用次数增加失败, `, error);
            },
          });
        },10*1000)
      },
      fail(error) {
        try {
          try {
            let errMsg = JSON.parse(error);
            let user = errMsg.user || '';
            let message = errMsg.message || '资源不足';
            let details = errMsg.details || '';
            Modal.error({
              title: "创建实验环境失败",
              content: (
                <div>
                  <div>{user}</div>
                  <div style={{ textIndent: '2em' }}>{message}</div>
                  <div>{details}</div>
                </div>
              ),
              width: '60%', // 设置宽度为 60%
              closable: true, // 右上角关闭按钮
              onCancel: () => {
                Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
              },
              footer: () => (
                <div class="d-flex justify-content-center my-3">
                  <button type="button" class="btn btn-primary" onClick={async () => {
                      router.push('/cost/topup')
                      Modal.destroyAll(); // 关闭所有弹窗
                    }}>补充资源</button>
                </div>
              ),
            });
          } catch (err) {
            logError("查询失败: 无法解析错误信息", err);
            Modal.error({
              title: "创建实验环境失败",
              content: (
                <div style={{ textIndent: '2em' }}>{JSON.stringify(getFailedMessage(error))}</div>
              ),
              width: '60%', // 设置宽度为 60%
              closable: true, // 右上角关闭按钮
              onCancel: () => {
                Modal.destroyAll(); // 点击关闭按钮时，关闭模态框
              },
              footer: () => (
                <div class="d-flex justify-content-center my-3">
                  <button type="button" class="btn btn-primary" onClick={() => {
                      Modal.destroyAll(); // 关闭所有弹窗
                    }}>关闭</button>
                </div>
              ),
            });
          }
        } catch (er) {
          logError("部署创建过程中发生未捕获的错误：", er);
          message.error('部署创建失败，请稍后重试。')
        }
      },
    }).then(function () {
      openCreateAppModal.value = false;
      confirmCreateAppLoading.value = false;
      formRefApp.value.resetFields();
      config_compute_power.value = false;
    });
  })
  .catch(error => {
    logDebug('error', error);
  });
};
const onCloseCreateAppModal = ()=>{
  openCreateAppModal.value = false;
  config_compute_power.value = false;
  confirmCreateAppLoading.value = false;
}

const commentTotal = ref(0);
const commentShowSizeChanger = ref(true);
const commentPageCurrent = ref(1);
const commentPageSize = ref(8);
const commentPageSizeOptions = ref(Array.from({length: 4}, (_, index) => (commentPageSize.value * (index + 1)).toString()));

const cardLoading = ref(false);
const data_list = reactive({
  id: '',
  name: '',
  description: '',
  description_html: '',
  used_nums: '',
  view_nums: '',
  like_count: 100,
  satisfaction_rating: 100,
  comment_total: 100,
  neutral_count: 0,
  dis_like_count: 0,
});

const formState = reactive({
  rate: 5,
  comment: '',
  app_id: '',
  comment_id: '',
  comment_like_nums: 0,
  comment_dislike_nums: 0,
});

const formStateReplay = reactive({
  rate: 5,
  comment: '',
  app_id: '',
  comment_id: '',
  comment_like_nums: 0,
  comment_dislike_nums: 0,
});

const commentData = ref([])
const subCommentData = ref([])

const openSubCommentData = reactive({id: 1})
const openModal = ref(false);
const cardSpinning = ref(false);

function openModalData(comment_rec) {
  Object.assign(openSubCommentData, comment_rec)
  openModal.value = true
}

const tabListNoTitle = [
  {
    key: 'app_description',
    tab: '应用介绍',
  },
  {
    key: 'app_help',
    tab: '帮助文件',
  },
  {
    key: 'app_comment',
    tab: '评论',
  },
];

const key = ref('app_description');
const noTitleKey = ref('app_description');
const onTabChange = (value, type, id) => {
  if (type === 'key') {
    key.value = value;
    logDebug("type", type);
  } else if (type === 'noTitleKey') {
    noTitleKey.value = value;
    if (value === 'app_comment') {
      app_id.value = id;
      getAppComment()
    }
  }
};
const formRef = ref();
const confirm_submit_loading = ref(false);
function sendAppComment(app_id) {
  //   发布评论
  if (formState.comment.length == 0) {
    message.error("请输入内容！")
    return false
  }

  jsonRPC({
    url: `/api/app_list/detail/${app_id}/comment/send`,
    params: {
      app_id: app_id,
      rate: formState.rate,
      comment_id: formState.comment_id,
      comment_like_nums: formState.comment_like_nums,
      comment_dislike_nums: formState.comment_dislike_nums,
      comment: formState.comment,
    },
    success(res) {
      logDebug(res)
      message.success(`发布评论成功`);
      formRef.value.resetFields();
      commentPageCurrent.value = 1;
      getAppComment()
    },
    fail(error) {
      logError(`发布评论失败, `, error);
      message.error(`发布评论失败，[${getFailedMessage(error)}]`);
    },
  });
}
const userId = sessionStorage.getItem('user_id');
const onCommentDelete = (e)=>{
  jsonRPC({
    url: "/api/app/comment/delete",
    params: {
      comment_id: e,
    },
    success() {
      commentPageCurrent.value = 1;
      getAppComment();
      message.success("删除成功！")
    },
    fail(error) {
      logError(`删除失败, `, error);
      message.error("删除失败！")
    },
  })
}
function sendSubAppComment(comment_rec) {
  //   发布子评论
  if (formStateReplay.comment.length == 0) {
    message.error("请输入内容！")
    return false
  }
  jsonRPC({
    url: `/api/app_list/detail/${app_id.value}/comment/send`,
    params: {
      app_id: app_id.value,
      rate: formStateReplay.rate,
      comment_id: comment_rec.id,
      comment: formStateReplay.comment,
    },
    success(res) {
      let data = getResponseData(res);
      logDebug(data);
      message.success(`发布评论成功`);
      formStateReplay.comment = ''
      getAppComment()
      openModal.value = false;
    },
    fail(error) {
      logError(`发布评论失败, `, error);
      message.error(`发布评论失败，[${getFailedMessage(error)}]`);
    },
  });
}

onMounted(() => {
  fetchData()
  fetchCreateDeploymentData()
});
watch([commentPageSize, commentPageCurrent], getAppComment);
function fetchData() {
  cardSpinning.value = true
  jsonRPC({
    url: `/api/app_list/detail/${app_id.value}`,
    params: {},
    success(res) {
      const data = getResponseData(res)
      logDebug(`查询详情成功app`, data);
      Object.assign(data_list, data);
      createAppForm.capacity_type_level = JSON.parse(data.capacity_type_level)
    },
    fail(error) {
      logError(`查询失败app, `, error);
    },
  }).then(()=>{
    cardSpinning.value = false
  });
}
function fetchCreateDeploymentData() {
  jsonRPC({
    url: "/vue/console/experiment/deployment/create/setting",
    params: {},
    success(res) {
      logDebug(`查询成功`, res.data.result.data);
      Object.assign(createAppForm, res.data.result.data);
    },
    fail(error) {
      logError(`查询失败, `, error);
    },
  });
}
async function getAppComment() {
  try {
    cardLoading.value = true;
    await jsonRPC({
      url: `/api/app_list/detail/comment`,
      params: {
        app_id: app_id.value,
        page_index: commentPageCurrent.value,
        page_size: commentPageSize.value,
      },
      success(res) {
        let data = getResponseData(res);
        logDebug(`获取评论`, data);
        commentTotal.value = data['record_count']
        commentData.value = data['data']
        subCommentData.value = data['sub_data']

        data_list.comment_total = data['comment_info']['comment_total']
        data_list.dis_like_count = data['comment_info']['dis_like_count']
        data_list.like_count = data['comment_info']['like_count']
        data_list.neutral_count = data['comment_info']['neutral_count']
        data_list.satisfaction_rating = data['comment_info']['satisfaction_rating']
      },
      fail(error) {
        logError(`获取评论失败, `, error);
        message.error(`获取评论失败，[${getFailedMessage(error)}]`);
      },
    }).then(()=>{
      cardLoading.value = false;
    });
    // 这里可以根据需要处理 res 的返回值，如果需要的话
  } catch (error) {
    cardLoading.value = false;
    logError(`获取评论失败, `, error);
    message.error(`获取评论失败，[${getFailedMessage(error)}]`);
  }
}
</script>

<style scoped lang="less">
.abcApps_detail {
  padding-top: 0 !important;
}
.text-muted{
  white-space: nowrap;
}
:where(.ant-radio-button-wrapper){
  height: 30px;
  min-width: 80px;
  line-height: 28px;
  border-radius: 4px;
  margin: 0 5px;
  border-inline-start-width: 1px;
  text-align: center;
  &:not(:first-child)::before{
    content: none
  }
  &:first-child{
    border-start-start-radius: 4px; 
    border-end-start-radius: 4px;
  }
  &:last-child{
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
  }
}
</style>