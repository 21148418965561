<template>
  <div v-if="canAccessEnv">
    <a-row id="header" @click="onFocus">
      <a-col id="header-left" :span="9">
        <div style="width:100%; height:100%; margin-left: 20px;
              display: flex; justify-content: flex-start; align-items: center">
          <a-typography-title :level="5">
            {{ appEnvName }}
          </a-typography-title>
        </div>
      </a-col>
      <a-col id="header-right" :span="15">
        <div v-for="item in functionItems" :key="item">
          <component class="functionItem" :is="item['feature']"
                     :primal="item['key']" :data="item['data']" :styleSetting="functionItemStyleSetting"
                     @ok="item['ok']" @cancel="item['cancel']"
                     @refresh="item['refresh']" @customize="item['customize']"/>
        </div>
      </a-col>
    </a-row>
    <a-row id="body">
      <a-col id="body-sider" :span="1" @click="onFocus">
        <a-menu v-model:selectedKeys="selectedEndpoints" mode="inline" :items="endpointItems"
                :inlineCollapsed="true" theme="light" :multiple="false"
                style="width: 100%"
                @click="clickEndpointItem"
        />
        <a-menu v-model:selectedKeys="selectedSwitches" mode="inline" :items="switchItems"
                :inlineCollapsed="true" theme="light" :multiple="false"
                style="width: 100%"
                @click="clickSwitchItem"
        />
      </a-col>
      <a-col id="body-content" :span="23">
        <div id="content-left" v-show="selectedSwitches.length>0">
          <div v-for="item in switchItems" :key="item">
            <div class="switch-item" v-show="selectedSwitches[0]===item['key']">
              <component :is="item['feature']" :primal="item['key']" :data="item['data']"
                         :styleSetting="switchItemStyleSetting"
                         @refresh="item['refresh']"
                         ref="switchItemRefs"/>
            </div>
          </div>
        </div>
        <div id="content-resizer" v-show="selectedSwitches.length>0" title="宽度调整条">⋮</div>
        <div id="content-right" @click="onFocus">
          <div v-for="item in endpointItems" :key="item">
            <div class="endpoint-item" v-show="selectedEndpoints[0]===item['key']">
              <component :is="item['feature']" :primal="item['key']" :data="item['data']"
                         :styleSetting="endpointItemStyleSetting"
                         @refresh="item['refresh']"
                         ref="endpointItemRefs"/>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
  <div v-else id="no_auth" style="width:100%; height:60vh;display: flex; justify-content: center; align-items: center;">
    <a-typography-title :level="2" style="color: red">您无权限访问该应用环境！</a-typography-title>
  </div>
</template>

<script setup>
import {h, markRaw, onMounted, onUnmounted, ref, watch} from 'vue';
import {
  AppstoreOutlined,
  BugOutlined,
  CodeOutlined,
  DesktopOutlined,
  FilePptOutlined,
  FolderOpenOutlined,
  GlobalOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons-vue';
import {isEmpty, isFalse, isNotEmpty, isTrue, toFloat, toInt, toPixel} from "@/utils/common_utils";
import TerminalShell from "./TerminalShell";
import TerminalVNC from "./TerminalVNC";
import TerminalService from "./TerminalService";
import TerminalHttp from "./TerminalHttp";
import TerminalFileSystem from "./TerminalFileSystem";
import TerminalManual from "./TerminalManual";
import TerminalFunctionFullScreen from "./TerminalFunctionFullScreen";
import TerminalFunctionRefresh from "./TerminalFunctionRefresh";
import TerminalFunctionVNCController from "./TerminalFunctionVNCController";
import TerminalFunctionVNCRefresh from "./TerminalFunctionVNCRefresh";
import TerminalFunctionAppSnapshot from "./TerminalFunctionAppSnapshot";
import TerminalFunctionStudentQuestion from "./TerminalFunctionStudentQuestion";
import TerminalFunctionStudentApp from "./TerminalFunctionStudentApp";
import TerminalFunctionFocus from "./TerminalFunctionFocus.vue";
import {logDebug, logError} from "@/utils/logger";
import {getRouterParam, getRouterQuery, routerReplace} from "@/utils/router_utils";
import {useRouter} from "vue-router";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";
import TerminalTtyd from "@/views/terminal/TerminalTtyd.vue";
import TerminalFunctionUpDownLoad from "@/views/terminal/TerminalFunctionUpDownLoad.vue";
import TerminalFunctionGoHome from "@/views/terminal/TerminalFunctionGoHome.vue";
import TerminalPPT from "@/views/terminal/TerminalPPT.vue";

const iconOptions = {
  style: {
    "fontSize": '24px',
    "marginLeft": '-4px',
    "marginTop": '8px',
  }
}

const router = useRouter()
logDebug(`ConsoleLiveForm getRouterParam(router)[${JSON.stringify(getRouterParam(router))}]`)
const solutionId = getRouterParam(router, 'solution_id')
const innerServiceName = getRouterParam(router, 'inner_service_name')
// 使用场景，比如：console（控制台）、live（直播）、contest（竞赛）等等，默认为空字符串。
const scene = getRouterQuery(router, 'scene', 'console')
// http://127.0.0.1:8080/#/terminal/solution/193/inner_service_name/linux
logDebug(`terminal, solutionId[${solutionId}], innerServiceName[${innerServiceName}], scene[${scene}]`)

const appEnvName = ref('');

const endpointItems = ref([]);
// 测试下来只有静态ref支持获取component实例
const endpointItemRefs = ref(undefined)
const selectedEndpoints = ref([]);

const getEndpointItemRefByPrimal = function (primal) {
  for (const endpointItemRef of endpointItemRefs.value) {
    if (isNotEmpty(endpointItemRef) && isNotEmpty(endpointItemRef.primal) && endpointItemRef.primal === primal) {
      return endpointItemRef
    }
  }
  return null
}

const handleEndpointItemRefByPrimal = function (primal, handlerName, handlerParams) {
  try {
    const endpointItemRef = getEndpointItemRefByPrimal(primal)
    if (endpointItemRef != null) {
      const handler = endpointItemRef[handlerName]
      if (typeof handler === 'function') {
        handler(handlerParams)
      }
    }
  } catch (e) {
    logDebug(`ignore handleEndpointItemRefByPrimal exception,`, e)
  }
}

watch(selectedEndpoints, function () {
  // 当selectedEndpoints区域的选项变化时，需要调用被选中组件的onSelected方法
  if (isNotEmpty(selectedEndpoints.value) && isNotEmpty(endpointItemRefs.value)) {
    const selectedKey = selectedEndpoints.value[0]
    handleEndpointItemRefByPrimal(selectedKey, 'onSelected', {})
  }
})

const canAccessEnv = ref(true)

const checkCanAccessEnv = function (data) {
  if (isTrue(data.can_access_env)) {
    canAccessEnv.value = true
  } else {
    canAccessEnv.value = false
    message.error(`您无权限访问该应用环境`, 3)
  }
}

const loadTerminalSetting = function (success, fail = undefined) {
  return jsonRPC({
    url: "/api/experiment/terminal/setting",
    params: {
      solution_id: solutionId,
      inner_service_name: innerServiceName,
      // 使用场景
      scene: scene,
    },
    success(res) {
      const data = getResponseData(res)
      if (isEmpty(data['id'])) {
        logError(`无效App数据[${JSON.stringify(data)}]`)
        message.error(`该应用不存在或者已经被删除，将自动跳转到“我的应用”页面！`, 3);
        routerReplace(router, '/console/app')
        return
      }

      checkCanAccessEnv(data)

      if (typeof success === 'function') {
        success(data)
      }
      return true
    },
    fail(error) {
      logError(`获取终端配置失败`, error)
      message.error(`获取终端配置失败，将自动跳转到“我的应用”页面！`, 3);
      if (typeof fail === 'function') {
        fail(error)
      }
      routerReplace(router, '/console/app')
      return false
    }
  })
}

const getHttpEndpoints = function (data) {
  const httpEndpoints = []
  if (isNotEmpty(data['env_http'])) {
    for (const item of data['env_http']) {
      if (isFalse(item['isEndpoint'])) {
        continue
      }
      const http = {
        key: `http_${item.id}`,
        icon: () => h(GlobalOutlined, iconOptions),
        label: item['name'],
        title: item['name'],
        feature: markRaw(TerminalHttp),
        data: item,
        type: 'http',
        port_id: item.id,
      }
      httpEndpoints.push(http)
    }
  }
  // id越大，越靠上显示
  httpEndpoints.sort((a, b) => b.port_id - a.port_id)
  return httpEndpoints
}

let terminalEndpoints = {}

const setEndpointItems = function (data) {
  terminalEndpoints = {}
  if (isTrue(data['shell_enable'])) {
    const shell_data = {
      env_id: data.id,
      is_teacher: data.is_teacher,
      teacher_data: data.teacher_data,
      user_id: data.user_id,
      session_id: data.session_id,
      shell_type: 'bash',
    }
    Object.assign(shell_data, data['shell_data'])
    terminalEndpoints['shell'] = {
      key: 'shell',
      icon: () => h(CodeOutlined, iconOptions),
      label: '命令行终端',
      title: '命令行终端',
      feature: markRaw(TerminalShell),
      data: shell_data,
      type: 'shell',
    }
  }
  if (isTrue(data['ttyd_enable'])) {
    terminalEndpoints['ttyd'] = {
      key: 'ttyd',
      icon: () => h(CodeOutlined, iconOptions),
      label: '命令行终端',
      title: '命令行终端',
      feature: markRaw(TerminalTtyd),
      data: data['ttyd_data'],
      type: 'ttyd',
    }
  }
  if (isTrue(data['vmshell_enable'])) {
    const shell_data = {
      env_id: data.id,
      is_teacher: data.is_teacher,
      teacher_data: data.teacher_data,
      user_id: data.user_id,
      session_id: data.session_id,
      shell_type: 'bash',
    }
    Object.assign(shell_data, data['vmshell_data'])
    terminalEndpoints['shell'] = {
      key: 'shell',
      icon: () => h(CodeOutlined, iconOptions),
      label: '命令行终端',
      title: '命令行终端',
      // vmshell使用的是TerminalShell，不是TerminalTtyd
      feature: markRaw(TerminalShell),
      data: shell_data,
      type: 'shell',
    }
  }
  if (isTrue(data['vnc_enable'])) {
    terminalEndpoints['vnc'] = {
      key: 'vnc',
      icon: () => h(DesktopOutlined, iconOptions),
      label: '图形化终端',
      title: '图形化终端',
      feature: markRaw(TerminalVNC),
      data: data['vnc_data'],
      type: 'vnc',
    }
  }
  if (isTrue(data['vm_enable'])) {
    terminalEndpoints['vnc_vm'] = {
      key: 'vnc_vm',
      icon: () => h(BugOutlined, iconOptions),
      label: '调试终端',
      title: '调试终端',
      feature: markRaw(TerminalVNC),
      data: data['vnc_data'],
      type: 'vnc_vm',
    }
  }
  if (isTrue(data['service_enable'])) {
    terminalEndpoints['service'] = {
      key: 'service',
      icon: () => h(AppstoreOutlined, iconOptions),
      label: '服务端口',
      title: '服务端口',
      feature: markRaw(TerminalService),
      data: {
        env_id: data.id
      },
      type: 'service',
      refresh: function (args) {
        logDebug('服务列表refresh事件', args)
        // 延迟 delayMS 毫秒后再更新菜单，避免同时改动子组件和父组件的布局，造成Vue报Resizer错误
        const delayMS = 600
        const timeout = setTimeout(function () {
          loadTerminalSetting(function (data) {
            const httpEndpoints = getHttpEndpoints(data)
            const newHttpEndpoints = []
            for (const newHttpEndpoint of httpEndpoints) {
              let found = null
              for (const oldHttpEndpoint of endpointItems.value) {
                if (newHttpEndpoint.key === oldHttpEndpoint.key) {
                  found = oldHttpEndpoint
                  break
                }
              }
              if (found !== null) {
                newHttpEndpoints.push(found)
              } else {
                newHttpEndpoints.push(newHttpEndpoint)
              }
            }
            const endpoints = [...newHttpEndpoints]
            for (const item of endpointItems.value) {
              if (item.type !== 'http') {
                endpoints.push(item)
              }
            }
            endpointItems.value = endpoints
            clearTimeout(timeout)
          })
        }, delayMS)
      },
    }
  }

  const httpEndpoints = getHttpEndpoints(data)
  const endpoints = [...httpEndpoints]
  const showTerminalsOrder = ['ttyd', 'vnc', 'shell', 'vnc_vm', 'service']
  for (const key of showTerminalsOrder) {
    if (isNotEmpty(terminalEndpoints[key])) {
      endpoints.push(terminalEndpoints[key])
    }
  }
  endpointItems.value = endpoints
}

const clickEndpointItem = function ({key}) {
  logDebug(`clickEndpointItem, key[${key}]`)
};

const switchItems = ref([])
// 测试下来只有静态ref支持获取component实例
const switchItemRefs = ref(undefined)
const selectedSwitches = ref([]);

let terminalSwitches = {}

const setSwitchItems = function (data) {
  terminalSwitches = {}
  if (isTrue(data['filesystem_enable'])) {
    terminalSwitches['filesystem'] = {
      key: 'filesystem',
      icon: () => h(FolderOpenOutlined, iconOptions),
      label: '文件系统',
      title: '文件系统',
      feature: markRaw(TerminalFileSystem),
      data: data['filesystem_data'],
    }
  }

  if (isNotEmpty(data['user_manual'])) {
    terminalSwitches['user_manual'] = {
      key: 'user_manual',
      icon: () => h(QuestionCircleOutlined, iconOptions),
      label: '用户手册',
      title: '用户手册',
      feature: markRaw(TerminalManual),
      data: data['user_manual'],
    }
  }

  if (isNotEmpty(data['ppt_ids'])) {
    terminalSwitches['ppt'] = {
      key: 'ppt',
      icon: () => h(FilePptOutlined, iconOptions),
      label: '课件',
      title: '课件',
      feature: markRaw(TerminalPPT),
      data: data['ppt_ids'],
    }
  }

  const switches = []
  const showTerminalsOrder = ['filesystem', 'user_manual', 'ppt']
  for (const key of showTerminalsOrder) {
    if (isNotEmpty(terminalSwitches[key])) {
      switches.push(terminalSwitches[key])
    }
  }

  switchItems.value = switches
}

const defaultLeftWidthRatio = ref(0.3)
const defaultRightWidthRatio = ref(0.7)

const recoverContentAreaSize = function () {
  const bodyContent = document.getElementById('body-content')
  const contentLeft = document.getElementById('content-left')
  const contentRight = document.getElementById('content-right')
  const resizerWidth = 10
  if (bodyContent && contentLeft && contentRight) {
    if (isNotEmpty(selectedSwitches.value)) {
      contentLeft.style.width = toPixel(defaultLeftWidthRatio.value * bodyContent.clientWidth)
      contentRight.style.width = toPixel(defaultRightWidthRatio.value * bodyContent.clientWidth - resizerWidth);
    } else {
      contentLeft.style.width = toPixel(0);
      contentRight.style.width = toPixel(bodyContent.clientWidth)
    }
  }
}

window.addEventListener('resize', recoverContentAreaSize, true);

watch(selectedSwitches, function () {
  // 当selectedSwitches区域的选项变化时，需要调用被选中组件的onSelected方法
  if (isNotEmpty(selectedSwitches.value) && isNotEmpty(switchItemRefs.value)) {
    const selectedKey = selectedSwitches.value[0]
    for (const switchItemRef of switchItemRefs.value) {
      if (isNotEmpty(switchItemRef) && isNotEmpty(switchItemRef.primal)) {
        if (switchItemRef.primal === selectedKey) {
          const onSelected = switchItemRef.onSelected
          if (typeof onSelected === 'function') {
            onSelected()
          }
        } else {
          const onUnSelected = switchItemRef.onUnSelected
          if (typeof onUnSelected === 'function') {
            onUnSelected()
          }
        }
      }
    }
  }
  // 如果都没有选中，也需要调用onUnSelected，这是所有标签页都被关闭的情况。
  else if (isEmpty(selectedSwitches.value) && isNotEmpty(switchItemRefs.value)) {
    for (const switchItemRef of switchItemRefs.value) {
      const onUnSelected = switchItemRef.onUnSelected
      if (typeof onUnSelected === 'function') {
        onUnSelected()
      }
    }
  }

  // 当selectedSwitches区域有折叠变化时，需要调整content-left和content-right的宽度
  recoverContentAreaSize()

  // 当selectedSwitches区域有折叠变化时，需要通知各个endpointItems进行刷新。
  // 当然，各个endpointItem组件刷不刷新就是他们自己控制的事情了。
  notifyTerminalEndpointsUpdate()
})

const notifyTerminalEndpointsUpdate = function () {
  logDebug('notifyTerminalEndpointsRefresh called')
  if (isNotEmpty(terminalEndpoints)) {
    for (const endpointItemRef of endpointItemRefs.value) {
      if (isNotEmpty(endpointItemRef)) {
        const onUpdate = endpointItemRef.onUpdate
        if (typeof onUpdate === 'function') {
          onUpdate()
        }
      }
    }
  }
}

let global_notify_refresh_timer = null
const debounceNotifyTerminalEndpointsUpdate = function () {
  if (global_notify_refresh_timer != null) {
    clearTimeout(global_notify_refresh_timer);
  }
  global_notify_refresh_timer = setTimeout(function () {
    notifyTerminalEndpointsUpdate()
  }, 200)
}

const clickSwitchItem = function ({key}) {
  logDebug(`clickSwitchItem, key[${key}]`)
  if (isNotEmpty(selectedSwitches.value) && `${selectedSwitches.value[0]}` === `${key}`) {
    selectedSwitches.value = []
  } else {
    selectedSwitches.value = [key]
  }
};

const functionItemStyleSetting = {
  iconStyle: {
    "fontSize": '24px',
  },
  buttonStyle: {
    "height": '32px',
    "width": '32px',
    "display": 'flex',
    "align-items": "center",
    "justify-content": "center",
    // "margin-bottom": "10px",
  }
}

const endpointItemStyleSetting = {
  contentStyle: {
    "height": '90vh',
  }
}
const endpointContentHeight = ref(endpointItemStyleSetting.contentStyle.height)

const switchItemStyleSetting = {
  contentStyle: {
    "height": '90vh',
  }
}
const switchContentHeight = ref(switchItemStyleSetting.contentStyle.height)

const functionItems = ref([])

// setTerminalPointerEvents用于禁止或打开VNC、HTTP等终端iframe响应鼠标事件，
// 这样在拖拽调整宽度时，鼠标才不会收到iframe里事件绑定的干扰
const setTerminalPointerEvents = function (enablePointerEvents = true) {
  logDebug(`setTerminalPointerEvents called, enablePointerEvents[${enablePointerEvents}]`)
  if (isNotEmpty(terminalEndpoints)) {
    for (const endpointItemRef of endpointItemRefs.value) {
      if (isNotEmpty(endpointItemRef)) {
        const setPointerEvents = endpointItemRef.setPointerEvents
        if (typeof setPointerEvents === 'function') {
          setPointerEvents(enablePointerEvents)
        }
      }
    }
  }
  if (isNotEmpty(terminalSwitches)) {
    for (const switchItemRef of switchItemRefs.value) {
      if (isNotEmpty(switchItemRef)) {
        const setPointerEvents = switchItemRef.setPointerEvents
        if (typeof setPointerEvents === 'function') {
          setPointerEvents(enablePointerEvents)
        }
      }
    }
  }
}

const dragResizeContentLeftRightWidth = function () {
  const bodySider = document.getElementById('body-sider')
  const bodyContent = document.getElementById('body-content')
  const contentResizer = document.getElementById("content-resizer");
  const contentLeft = document.getElementById('content-left')
  const contentRight = document.getElementById('content-right')
  contentResizer.onmousedown = function (mouseDownEvent) {
    // 暂时禁止VNC、HTTP等终端iframe响应鼠标事件，避免拖拽时鼠标收到iframe里绑定事件的干扰
    setTerminalPointerEvents(false)
    // 拖拽区 变色
    contentResizer.style.background = "#818181";
    // 鼠标按下时的宽度位置
    const startWidth = mouseDownEvent.clientX;
    // content-resizer区域宽度方向左边界位置记录到自定义属性startLeftWhenMouseDown上
    contentResizer.startLeftWhenMouseDown = Math.max(contentResizer.offsetLeft - bodySider.clientWidth, 0);
    /* 鼠标拖拽 */
    document.onmousemove = function (mouseMoveEvent) {
      // contentWidth 是 body-content 区域的宽度
      const contentWidth = bodyContent.clientWidth
      logDebug(`contentWidth = ${contentWidth}`)
      const minLeftWidth = toInt(contentWidth * 0.1)
      const maxLeftWidth = toInt(contentWidth * 0.9)
      const resizerWidth = 10
      // endWidth是鼠标移动后宽度方向的位置，所以 endWidth - startWidth 表示鼠标在宽度方向移动的距离
      const endWidth = mouseMoveEvent.clientX;
      // leftWidth表示content-resizer区域在宽度方向上移动后的左边界位置，所以，同时leftWidth也是content-left区域的宽度。
      let leftWidth = contentResizer.startLeftWhenMouseDown + (endWidth - startWidth)
      /* 调整content-left区域最小宽度 */
      if (leftWidth < minLeftWidth) {
        leftWidth = minLeftWidth
      }
      /* 调整content-left区域最大宽度 */
      if (leftWidth > maxLeftWidth) {
        leftWidth = maxLeftWidth
      }
      // 设置content-resizer区域在宽度方向的左边界位置
      contentResizer.style.left = toPixel(leftWidth);
      // 设置content-left区域在宽度方向的宽度
      contentLeft.style.width = toPixel(leftWidth);
      // 设置右边宽度
      contentRight.style.width = toPixel(contentWidth - leftWidth - resizerWidth);

      debounceNotifyTerminalEndpointsUpdate()
    }
    /* 鼠标松开 */
    document.onmouseup = function () {
      // 取消事件
      document.onmousemove = null;
      document.onmouseup = null;
      // 恢复颜色
      contentResizer.style.background = "blue";
      // 记录body-content区域、content-left区域和content-right区域当前宽度
      let leftWidthRatio = toFloat(
          contentLeft.style.width.replace('px', '')) / bodyContent.clientWidth
      if (leftWidthRatio < 0.1) {
        leftWidthRatio = 0.1
      }
      if (leftWidthRatio > 0.9) {
        leftWidthRatio = 0.9
      }
      defaultLeftWidthRatio.value = leftWidthRatio
      defaultRightWidthRatio.value = 1 - leftWidthRatio
      // 重新允许VNC、HTTP等终端iframe响应鼠标事件
      setTerminalPointerEvents(true)
    }
  }
}

const onFocus = function () {
  if (isNotEmpty(selectedEndpoints.value) && isNotEmpty(endpointItemRefs.value)) {
    const selectedKey = selectedEndpoints.value[0]
    logDebug(`TerminalFunctionFocus, selectedKey[${selectedKey}]`)
    handleEndpointItemRefByPrimal(selectedKey, 'onConfig', {
      event: 'focus'
    })
  }
}

const setFunctionItems = function (data) {
  // 根据后端传来的数据，决定右上角展示哪些功能按钮
  const enableFunctionItems = []
  if (data['scene'] === 'console') {
    enableFunctionItems.push({
      key: 'home',
      label: '回到首页',
      title: '回到首页',
      feature: markRaw(TerminalFunctionGoHome),
    })
  }
  if (isTrue(data['ttyd_enable'])) {
    enableFunctionItems.push({
      key: 'up_download',
      label: '文件上传下载',
      title: '文件上传下载',
      feature: markRaw(TerminalFunctionUpDownLoad),
      data: data['ttyd_data'],
    })
  }
  if (isTrue(data['vmshell_enable'])) {
    enableFunctionItems.push({
      key: 'up_download',
      label: '文件上传下载',
      title: '文件上传下载',
      feature: markRaw(TerminalFunctionUpDownLoad),
      // 虽然使用vmshell，但是依然需要使用ttyd来进行文件上传下载
      data: data['ttyd_data'],
    })
  }
  enableFunctionItems.push({
    key: 'fullscreen',
    label: '全屏',
    title: '全屏',
    feature: markRaw(TerminalFunctionFullScreen),
    data: {
      'arg001': '可选，示例参数'
    },
    ok: function (args) {
      logDebug('可选，回调函数ok示例', args)
    },
    cancel: function (args) {
      logDebug('可选，回调函数cancel示例', args)
    },
    refresh: function (args) {
      logDebug('可选，回调函数refresh示例', args)
    },
    customize: function (args) {
      logDebug('可选，回调函数customize示例', args)
    }
  })
  enableFunctionItems.push({
    key: 'refresh',
    label: '刷新',
    title: '刷新',
    feature: markRaw(TerminalFunctionRefresh),
  })
  enableFunctionItems.push({
    key: 'app_snapshot',
    label: '应用快照',
    title: '应用快照',
    feature: markRaw(TerminalFunctionAppSnapshot),
    data: {
      env_id: data.id,
      app_env_id: data.app_env_id,
      vnc_data: data['vnc_data'],
      vm_enable: data['vm_enable'],
    }
  })
  if (isTrue(data['vnc_enable'])) {
    enableFunctionItems.push({
      key: 'control_panel_vnc',
      label: 'control_panel_vnc label',
      title: 'control_panel_vnc title',
      feature: markRaw(TerminalFunctionVNCController),
      ok: function (args) {
        logDebug('TerminalFunctionRefresh ok', args)
        if (isNotEmpty(endpointItemRefs.value)) {
          handleEndpointItemRefByPrimal('vnc', 'onConfig', {
            event: 'control_panel_vnc'
          })
          handleEndpointItemRefByPrimal('vnc_vm', 'onConfig', {
            event: 'control_panel_vnc'
          })
        }
      },
    })
    enableFunctionItems.push({
      key: 'control_refresh_vnc',
      label: 'control_refresh_vnc label',
      title: 'control_refresh_vnc title',
      feature: markRaw(TerminalFunctionVNCRefresh),
      ok: function (args) {
        logDebug('TerminalFunctionVNCRefresh ok', args)
        if (isNotEmpty(endpointItemRefs.value)) {
          handleEndpointItemRefByPrimal('vnc', 'onConfig', {
            event: 'control_refresh_vnc'
          })
          handleEndpointItemRefByPrimal('vnc_vm', 'onConfig', {
            event: 'control_refresh_vnc'
          })
        }
      },
    })
  }
  enableFunctionItems.push({
    key: 'focus',
    label: 'focus label',
    title: 'focus title',
    feature: markRaw(TerminalFunctionFocus),
    ok: function (args) {
      logDebug('TerminalFunctionFocus ok', args)
      onFocus()
    },
  })
  if (isTrue(data['is_teacher']) && isTrue(data['is_live'])) {
    enableFunctionItems.push({
      key: 'student_question',
      label: '学生提问',
      title: '学生提问',
      feature: markRaw(TerminalFunctionStudentQuestion),
      data: {
        live_id: data.live_id,
      }
    })

    enableFunctionItems.push({
      key: 'student_app',
      label: '学生应用',
      title: '学生应用',
      feature: markRaw(TerminalFunctionStudentApp),
      data: {
        live_id: data.live_id,
      }
    })
  }
  const enableFunctionItemMap = {}
  for (const item of enableFunctionItems) {
    enableFunctionItemMap[item.key] = item
  }

  // 按照 enableFunctionItemOrder 的顺序显示右上角的功能按钮
  const enableFunctionItemOrder = [
    'home', 'up_download', 'fullscreen', 'refresh', 'control_panel_vnc', 'app_snapshot',
    'control_refresh_vnc', 'student_question', 'student_app'
  ]
  // functionItemsValue 是排好序的功能按钮
  const functionItemsValue = []
  for (const item of enableFunctionItemOrder) {
    if (isNotEmpty(enableFunctionItemMap[item])) {
      functionItemsValue.push(enableFunctionItemMap[item])
    }
  }

  // 更新functionItems的状态
  functionItems.value = functionItemsValue
}

const setAppEnvName = function (data) {
  if (isTrue(data['is_scene_contest'])) {
    appEnvName.value = data['template_name'];
  } else if (isTrue(data['is_scene_live'])) {
    appEnvName.value = '';
  } else {
    appEnvName.value = data['name'];
  }
}

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`Terminal is now mounted.`)
  dragResizeContentLeftRightWidth();

  const loadTerminalSettingResult = loadTerminalSetting(function (data) {
    setAppEnvName(data)
    setEndpointItems(data)
    setSwitchItems(data)
    setFunctionItems(data)
  })

  loadTerminalSettingResult.then(function (result) {
    logDebug(`getTerminalSettingResult[${result}]`)
    if (isTrue(result)) {
      // 如果设置terminal成功，就将左侧栏的选中菜单设置为第一个endpointItem
      selectedEndpoints.value = [endpointItems.value[0].key]
    } else {
      message.error('跳转失败')
    }
  }).then(() => {
    const livePlayerLive = document.getElementById('live-player-live');
    const videoElement = livePlayerLive ? livePlayerLive.querySelector('video') : null;

    if (videoElement) {
      // 创建按钮元素
      const actionButton = document.createElement('button');
      actionButton.textContent = '画中画';
      actionButton.style.display = 'none';
      actionButton.style.position = 'absolute';
      actionButton.style.bottom = '30px';
      actionButton.style.left = '50%';
      actionButton.style.transform = 'translate(-50%, -50%)';
      actionButton.style.borderRadius = '4px';
      actionButton.style.padding = '0 5px';

      // 将按钮添加到 livePlayerLive 容器中
      livePlayerLive.appendChild(actionButton);

      // 鼠标移入容器时显示按钮
      livePlayerLive.addEventListener('mouseenter', () => {
        actionButton.style.display = 'block';
      });

      // 鼠标移出容器时隐藏按钮
      livePlayerLive.addEventListener('mouseleave', () => {
        actionButton.style.display = 'none';
      });

      // 按钮点击事件
      actionButton.addEventListener('click', () => {
        if (document.pictureInPictureElement) {
          // 退出画中画模式
          document.exitPictureInPicture().catch(error => {
            logError('Failed to exit Picture-in-Picture mode:', error);
          });
          logDebug("关闭画中画");
        } else {
          // 进入画中画模式
          videoElement.requestPictureInPicture().catch(error => {
            logError('Failed to enter Picture-in-Picture mode:', error);
          });
          logDebug("打开画中画");
        }
      });
    }
  })
})
onUnmounted(() => {
  window.removeEventListener('resize', recoverContentAreaSize, true);
});
</script>

<style scoped lang="scss">
#header {
  background: beige;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 8vh;
}

#header-left {
  background: white;
}

#header-right {
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

#header-right .functionItem {
  margin-right: 10px;
}

#body {
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: v-bind(endpointContentHeight);
}

#body-sider {
  background: white;
}

#body-content {
  background: white;
  width: 100%;
  height: v-bind(endpointContentHeight);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#content-left {
  width: 30%;
  height: v-bind(switchContentHeight);
  background: white;
  border: solid 2px #007bff;
  overflow: hidden;
}

#content-right {
  /*padding: 5px 0 0 10px;*/
  width: 100%;
  height: v-bind(endpointContentHeight);
  background: white;
}

/*拖拽区div样式*/
#content-resizer {
  cursor: col-resize;
  background: blue;
  border-radius: 5px;
  margin-top: 40vh;
  width: 10px;
  height: 50px;
  font-size: 32px;
  color: white;
}

/*拖拽区鼠标悬停样式*/
#content-resizer:hover {
  color: #444444;
}

</style>
