import { defineStore } from 'pinia'
import {reactive,toRefs,ref} from 'vue';
import arrays from '@/utils/arrays'
import helper from '@/utils/helper.js'
import REGISTER from '@/register/index.js'
import xtermTheme from 'xterm-theme';
import { message } from 'ant-design-vue';

const coreStore = defineStore('core', () => {
	const state = reactive({
		name:"hi168 Windows 桌面 UI",
		dateFormat: 'YYYY-MM-DD',
		startMenu: false,
		sidebar: false,
		livebar: false,
		wallpaper: "./wall/wall-17.jpg",
		themeColor: '#4098f6',
		contextMenu: {
			show: false,
			type: 'wall',
			x: 0,
			y: 0,
			data: {}
		},
		applications: [],
		tasks: [],
		xtermTheme: null,  // xterm主题
	});

	const init = async function() {
		this.getThemeColor(); // 初始化主题颜色
		state.wallpaper = helper.getLocalstorage('wallpaper', "./wall/wall-17.jpg");  // 初始化壁纸
		state.xtermTheme = helper.getLocalstorage('xtermTheme', xtermTheme['Atom']);  // 初始xterm主题
		state.applications = REGISTER.application.flatMap(item => {
			if (item.sublist || item.type === 'folder') {
				return item.sublist.map(subitem => createApplication(subitem));
			} else {
				return createApplication(item);
			}
		});
		danmaku.fontColor = helper.getLocalstorage('danmakuFontColor', danmaku.fontColor);
		danmaku.fontSize = helper.getLocalstorage('danmakuFontSize', danmaku.fontSize);
	}

	function createApplication(item) {
		const {
			id = '',
			name = '',
			icon = '',
			width = 0,
			height = 0,
			page = '',
			abcPage = '',
			resizable = true, // 显示右上角最大化最小化
			loading = false, // 桌面应用图标记载中动画
			link = false, // 外部链接
		} = item;
		return {
			id,
			name,
			icon,
			width: width > 0 ? width : 0,
			height: height > 0 ? height : 0,
			page,
			abcPage,
			resizable: helper.ObjectNotNull(resizable) ? resizable : true,
			link: helper.ObjectNotNull(link) ? link : false,
			loading,
			selected: false, // 桌面主题背景图片选中
		};
	}

	// 打开应用
	const openApplication = function(id){
		let i = arrays.findIndexById(state.tasks,id);
		if(i < 0){
			let object = state.applications.filter(t => t.id == id)[0]
			if (!object) {
				return message.error("该应用不存在，或不是您创建的。");
			}
			let task = reactive({})
			task.title = object.name
			task.id = object.id
			task.icon = object.icon
			task.width = object.width
			task.height = object.height
			task.page = helper.ObjectNotNull(object.page) ? object.page : ""
			task.abcPage = helper.ObjectNotNull(object.abcPage) ? object.abcPage : ""
			task.resizable = helper.ObjectNotNull(object.resizable) ? object.resizable : true
			task.loading = false
			task.min = false
			task.focus = true
			task.date = new Date()
			task.lastDate = new Date()
			state.tasks.push(task);
			applicationSort();
		} else {
			let i = arrays.findIndexById(state.tasks,id)
			if(i < state.tasks.length) {
				let app = state.tasks[i];
				if(app.min == true){
					app.min = false
				}
			}
			applicationFocus(id);
		}
	}

	// 关闭应用
	const closeApplication = function(id) {
		let i = state.tasks.findIndex(task => task.id == id);
		if(i != -1) { // 找到了对应的任务
			state.tasks.splice(i, 1); // 删除对应索引的任务
		}
	}
	
	
	// 聚焦
	const applicationFocus = function(id,lastDate){
		let array = arrays.findAllIndexesById(state.tasks,id)
		if (array.length>1) {
			for (let index = 0; index < array.length; index++) {
				const i = array[index];
				if(i < state.tasks.length){
					let app = state.tasks[i]
					if(app.lastDate == lastDate){
						app.lastDate = new Date()
					}
				}
			}
		} else {
			let i = array[0];
			if(i < state.tasks.length) {
				let app = state.tasks[i];
				app.lastDate = new Date();
			}
		}
		applicationSort();
	}
	
	const applicationSort = function() {
		state.tasks.sort((a, b) => {
			return b.lastDate - a.lastDate
		})
	}
	// 最小化
	const applicationMinSwitch = function(id,lastDate) {
		let array = arrays.findAllIndexesById(state.tasks,id)
		if (array.length>1) {
			for (let index = 0; index < array.length; index++) {
				const i = array[index];
				if(i < state.tasks.length){
					let app = state.tasks[i]
					if(app.lastDate == lastDate){
						if(app.focus == true){
							app.min = true
							app.lastDate = state.tasks[array.length - 1].lastDate - 1
						} else if(app.min == true){
							app.min = false
							app.lastDate = new Date()
						} else {
							app.lastDate = new Date()
						}
					}
				}
			}
		} else {
			let i = array[0];
			if(i < state.tasks.length){
				let app = state.tasks[i]
				if(app.focus == true){
					app.min = true
					app.lastDate = state.tasks[state.tasks.length - 1].lastDate - 1
				} else if(app.min == true){
					app.min = false
					app.lastDate = new Date()
				} else {
					app.lastDate = new Date()
				}
			}
		}
		applicationSort();
	}
	
	const selectIcon = function(id) {
		state.applications.forEach(item => {
			if (id == item.id) {
				item.selected = true
			} else {
				item.selected = false
			}
		})
		if (state.startMenu) {
			state.startMenu = false
		}
	}
	// 打开菜单
	const openMenu = function() {
		state.startMenu = !state.startMenu
	}

	// 设置壁纸
	const setWallpaper = function(url){
		state.wallpaper = url;
		helper.setLocalstorage('wallpaper', url)
	}
	// 设置壁纸
	const setXtermTheme = function(e){
		state.xtermTheme = e;
		helper.setLocalstorage('xtermTheme', e)
	}
	// 打开右键菜单
	const showContextMenu = function(x,y,type,data) {
		state.contextMenu.show = true;
		state.contextMenu.x = x;
		state.contextMenu.y = y;
		state.contextMenu.type = type;
		state.contextMenu.data = data;
	}
	// 关闭右键菜单
	const closeContextMenu = function() {
		if(state.contextMenu.show){
			state.contextMenu.show = false;
			// state.contextMenu.x = 0;
			// state.contextMenu.y = 0;
			state.contextMenu.type = '';
			state.contextMenu.data = {};
		}
	}
	
	const switchSidebar = function(flag) {
		if(helper.ObjectNotNull(flag)){
			state.sidebar = flag
		} else {
			state.sidebar = !state.sidebar
		}
	}

	const switchLivebar = function(flag) {
		if(helper.ObjectNotNull(flag)){
			state.livebar = flag
		} else {
			state.livebar = !state.livebar
		}
	}
	// 设置主题颜色
	const setThemeColor = function(color) {
		state.themeColor = color;
		helper.setLocalstorage('m_themeColor',color);
		document.documentElement.setAttribute('style', '--theme-color: ' + color);
	}
	// 获取主题颜色
	const getThemeColor = function() {
		let themeColor = helper.getLocalstorage('m_themeColor',"");
		if(helper.StringIsNull(themeColor)) {
			document.documentElement.setAttribute('style', '--theme-color: ' + state.themeColor);
			return state.themeColor;
		} else {
			document.documentElement.setAttribute('style', '--theme-color: ' + themeColor);
			return themeColor;
		}
	}

	// 直播弹幕
	const danmakuRef = ref()
	// 设置
	const liveInit = (e)=>{
		danmakuRef.value = e
	}
	// 开始/继续播放
	const livePay = ()=>{
		if (danmakuRef.value) {
			danmakuRef.value.play()
		}
	}
	// 停止播放并清空弹幕
	const liveStop = ()=>{
		if (danmakuRef.value) {
			danmakuRef.value.stop()
		}
	}
	// 获取状态
	const getPlayState = ()=>{
		return danmakuRef.value.getPlayState()
	}
	const danmaku = reactive({
		fontColor: '#ff0000',
		fontSize: '40px',
	})
	const setDanmakuColor = (e)=>{
		danmaku.fontColor = e;
		helper.setLocalstorage('danmakuFontColor', e);
	}
	const setDanmakuFontSize = (e)=>{
		danmaku.fontSize = e;
		helper.setLocalstorage('danmakuFontSize', e);
	}

	return {
		init,applicationSort,
		openApplication,closeApplication,applicationMinSwitch,applicationFocus,
		selectIcon,showContextMenu,closeContextMenu,
		setWallpaper,switchSidebar,switchLivebar,openMenu,setThemeColor,getThemeColor,
		liveInit,livePay,liveStop,getPlayState,
		setDanmakuColor,setDanmakuFontSize,
		setXtermTheme,
		...toRefs(state),
		...toRefs(danmaku),
	}
});
export default coreStore