import {createRouter, createWebHashHistory} from "vue-router";

// 定义 (路由) 组件。
// 可以从其他文件 import 进来
import Layout from "../layout/Layout.vue";
import Home from "@/views/home/Home";
import HomeNew from "@/views/home/HomeNew";
import newsDetail from "@/views/home/newsDetail";
import newsList from "@/views/home/newsList";
import MyAccount from "@/applications/sys/users";
import WebComponent from "@/views/login/WebComponent";
import NotFound from "@/views/notFound/NotFound";
import Live from "@/views/live/Live";
import LiveInfo from "@/views/live/LiveInfo";
import LiveDetail from "@/views/live/LiveDetail";
import LiveRtc from "@/views/live/LiveRtc.vue";
import Course from "@/views/course/Course";
import CourseDetail from "@/views/course/CourseDetail";
import Job from "@/views/job/Job";
import JobDetail from "@/views/job/JobDetail";
import JobHiring from "@/views/job/JobHiring";
import JobExercise from "@/views/job/JobExercise";
import Forum from "@/views/forum/Forum";
import ForumSection from "@/views/forum/ForumSection";
import Blog from "@/views/blog/Blog";
import BlogCategory from "@/views/blog/BlogCategory";
import BlogDetail from "@/views/blog/BlogDetail";
import BlogWrite from "@/views/blog/BlogWrite";
import BlogHomepage from "@/views/blog/BlogHomepage";
import BlogAttention from "@/views/blog/BlogAttention";
import BlogFan from "@/views/blog/BlogFan";
import BlogMyComponent from "@/views/blog/BlogMyComponent";
import App from "@/views/app/App";
import AppDetail from "@/views/app/AppDetail";
import AppList from "@/views/app/AppList";
import Console from "@/views/console/Console";
import shopCart from "@/views/shop/shopCart";
import shopCheck from "@/views/shop/shopCheck";
import shopOrder from "@/views/shop/shopOrder";
import shopPay from "@/views/shop/shopPay";
import buyResource from "@/views/shop/buyResource";
import Cost from "@/views/cost/Cost";
import TopUp from "@/views/cost/TopUp";
import ConsoleHome from "@/views/console/ConsoleHome";
import ConsoleEduAdmin from "@/views/console/ConsoleEduAdmin";
import ConsoleEduAdminClass from "@/views/console/ConsoleEduAdminClass";
import ConsoleEduAdminClassCourse from "@/views/console/ConsoleEduAdminClassCourse";
import ConsoleEduAdminClassCourseAlgorithm from "@/views/console/ConsoleEduAdminClassCourseAlgorithm";
import ConsoleEduAdminClassCourseSignIn from "@/views/console/ConsoleEduAdminClassCourseSignIn";
import ConsoleEduAdminClassCourseWork from "@/views/console/ConsoleEduAdminClassCourseWork";
import ConsoleEduAdminClassCourseWorkPublish from "@/views/console/ConsoleEduAdminClassCourseWorkPublish";
import ConsoleEduAdminClassCourseWorkBatch from "@/views/console/ConsoleEduAdminClassCourseWorkBatch";
import ConsoleEduAdminClassCourseReport from "@/views/console/ConsoleEduAdminClassCourseReport";
import ConsoleEduAdminClassCourseReportBatch from "@/views/console/ConsoleEduAdminClassCourseReportBatch";
import ConsoleEduAdminStudent from "@/views/console/ConsoleEduAdminStudent";
import ConsoleClass from "@/views/console/ConsoleClass";
import ConsoleClassDetail from "@/views/console/ConsoleClassDetail";
import ConsoleClassCourse from "@/views/console/ConsoleClassCourse";
import ConsoleClassCourseWork from "@/views/console/ConsoleClassCourseWork";
import ConsoleClassForm from "@/views/console/ConsoleClassForm";
import ConsoleClassHomeworkCorrect from "@/views/console/ConsoleClassHomeworkCorrect";
import ConsoleClassHomeworkFinish from "@/views/console/ConsoleClassHomeworkFinish";
import ConsoleClassHomeworkForm from "@/views/console/ConsoleClassHomeworkForm";
import ConsoleClassHomeworkDetail from "@/views/console/ConsoleClassHomeworkDetail";
import ConsoleClassWrongQuestionSetDetail from "@/views/console/ConsoleClassWrongQuestionSetDetail";
import ConsoleLive from "@/views/console/ConsoleLive";
import ConsoleLiveDetail from "@/views/console/ConsoleLiveDetail";
import ConsoleLiveForm from "@/views/console/ConsoleLiveForm";
import ConsoleApp from "@/views/console/ConsoleApp";
import ConsoleJob from "@/views/console/ConsoleJob";
import ConsoleJobForm from "@/views/console/ConsoleJobForm";
import certification from "@/views/training/certification.vue";
import certificationDetail from "@/views/training/certificationDetail.vue";
import certificationExam from "@/views/training/exam.vue";
import ConsoleAppEnvironment from "@/views/console/ConsoleAppEnvironment";
import ConsoleAppEnvironmentDetail from "@/views/console/ConsoleAppEnvironmentDetail";
import ConsoleAppTemplate from "@/views/console/ConsoleAppTemplate";
import ConsoleAppNamespace from "@/views/console/ConsoleAppNamespace";

import Terminal from "@/views/terminal/Terminal";
import Terminal2 from "@/views/terminal/Terminal2";
import Desktop from "@/views/desktop/Desktop";
import Download from "@/views/desktop/Download";
import About from "@/views/about/About.vue";
import ConsoleCourse from "@/views/console/ConsoleCourse.vue";
import ConsoleCourseCreate from "@/views/console/ConsoleCourseCreate.vue";
import ConsoleCourseDetail from "@/views/console/ConsoleCourseDetail.vue";
import ConsoleCourseHistory from "@/views/console/ConsoleCourseHistory.vue";
import ConsoleCourseLessonEdit from "@/views/console/ConsoleCourseLessonEdit.vue";
import ConsoleCourseLessonEditExercise from "@/views/console/ConsoleCourseLessonEditExercise.vue";
import ConsoleCourseDemand from "@/views/console/ConsoleCourseDemand.vue";
import ConsoleImage from "@/views/console/ConsoleImage.vue";
import ConsoleImageContainerDetail from "@/views/console/ConsoleImageContainerDetail.vue";
import ConsoleImageAppCreate from "@/views/console/ConsoleImageAppCreate.vue";
import ConsoleImageContainerCreate from "@/views/console/ConsoleImageContainerCreate.vue";
import ConsoleImageAppDetail from "@/views/console/ConsoleImageAppDetail.vue";
import DesktopComponent from "@/views/login/DesktopComponent.vue";
import ConsoleImageContainer from "@/views/console/ConsoleImageContainer.vue";
import ConsoleImageVirtualMachineDetail from "@/views/console/ConsoleImageVirtualMachineDetail.vue";
import ConsoleImageVirtualMachineCreate from "@/views/console/ConsoleImageVirtualMachineCreate.vue";
import ConsoleImageVirtualMachine from "@/views/console/ConsoleImageVirtualMachine.vue";

const routes = [
    {
        path: '/',
        component: Layout,
        meta: {title: 'Hi168'},
        children: [
            {
                path: '',
                component: HomeNew,
                name: Home,
                meta: {title: '首页'},
            },
            {
                path: 'home',
                component: HomeNew,
                meta: {title: '首页'},
            },
            {
                path: 'homeNew',
                component: Home,
                meta: {title: '首页'},
            },
            {
                path: 'news',
                component: newsList,
                meta: {title: '新闻'},
            },
            {
                path: 'news/:id',
                component: newsDetail,
                meta: {title: '新闻详情'},
            },
            {
                path: 'about',
                component: About,
                meta: {title: '关于'},
            },
            {
                path: 'download',
                component: Download,
                meta: {title: '下载'},
            },
            {
                path: '/login',
                component: WebComponent,
                meta: {title: '登录'},
            },
            {
                path: 'live',
                component: Live,
                meta: {title: '直播'},
            },
            {
                path: 'live/info/:id',
                component: LiveInfo,
                meta: {title: '直播详情'},
            },
            {
                path: 'live/:live_id',
                component: LiveDetail,
                meta: {title: '直播详情'},
            },
            {
                path: 'course',
                component: Course,
                meta: {title: '课程'},
            },
            {
                path: 'course/:course_id',
                component: CourseDetail,
                meta: {title: '课程详情'},
            },
            {
                path: 'app',
                component: AppList,
                meta: {title: '应用'},
            },
            {
                path: 'app/:app_id',
                component: AppDetail,
                meta: {title: '应用详情'},
            },
            {
                path: 'app_list',
                component: App,
                meta: {title: '应用'},
            },
            {
                path: 'training',
                component: certification,
                meta: {title: '证书培训'},
            },
            {
                path: 'training/:id',
                component: certificationDetail,
            },
            {
                path: 'training/:id/:exam_id',
                component: certificationExam,
            },
            {
                path: 'job',
                component: Job,
                meta: {title: '校园招聘'},
            },
            {
                path: 'job/:id',
                component: JobDetail,
            },
            {
                path: 'job/hiring/:id',
                component: JobHiring,
            },
            {
                path: 'job/exercise',
                component: JobExercise,
            },
            {
                path: 'forum',
                component: Forum,
                meta: {title: '技术论坛'},
            },
            {
                path: 'forum/section/:id',
                component: ForumSection,
                meta: {title: '论坛专题'},
            },
            {
                path: 'blog',
                component: Blog,
                meta: {title: '互动博客'},
            },
            {
                path: 'blog/:id',
                component: BlogDetail,
            },
            {
                path: 'blog/category/:id',
                component: BlogCategory,
            },
            {
                path: 'blog/write',
                component: BlogWrite,
            },
            {
                path: 'blog/homepage',
                component: BlogHomepage,
            },
            {
                path: 'blog/attention',
                component: BlogAttention,
            },
            {
                path: 'blog/fan',
                component: BlogFan,
            },
            {
                path: 'blog/message',
                component: BlogMyComponent,
            },
            {
                path: 'my',
                component: MyAccount,
                meta: {title: '我的账号'},
            },
            {
                path: '/shop',
                component: shopCart,
                meta: {title: '购物车'},
            },
            {
                path: '/check',
                component: shopCheck,
            },
            {
                path: '/order',
                component: shopOrder,
            },
            {
                path: '/pay',
                component: shopPay,
            },
            {
                path: '/buyResource',
                component: buyResource,
                meta: {title: '购买资源'},
            },
            {
                path: 'cost',
                component: Cost,
                meta: {title: '费用中心'},
            },
            {
                path: 'cost/topup',
                component: TopUp,
                meta: {title: '充值'},
            },
            {
                path: 'console',
                component: Console,
                meta: {title: '控制台'},
                children: [
                    {
                        path: '',
                        component: ConsoleHome,
                        meta: {title: '控制台-首页'},
                    },
                    {
                        path: 'home',
                        component: ConsoleHome,
                        meta: {title: '控制台-首页'},
                    },
                    {
                        path: 'live',
                        component: ConsoleLive,
                        meta: {title: "控制台-直播"}
                    },
                    {
                        path: 'live/:live_id',
                        component: ConsoleLiveDetail,
                        meta: {title: "控制台-直播详情"}
                    },
                    {
                        path: 'live/form/:live_id',
                        component: ConsoleLiveForm,
                    },
                    {
                        path: 'eduAdmin',
                        component: ConsoleEduAdmin,
                        meta: {title: "控制台-教务管理"}
                    },
                    {
                        path: 'eduAdmin/class',
                        component: ConsoleEduAdminClass,

                    },
                    {
                        path: 'eduAdmin/class/course',
                        component: ConsoleEduAdminClassCourse,
                    },
                    {
                        path: 'eduAdmin/class/course/algorithm',
                        component: ConsoleEduAdminClassCourseAlgorithm,
                    },
                    {
                        path: 'eduAdmin/class/course/signIn',
                        component: ConsoleEduAdminClassCourseSignIn,
                    },
                    {
                        path: 'eduAdmin/class/course/work',
                        component: ConsoleEduAdminClassCourseWork,
                    },
                    {
                        path: 'eduAdmin/class/course/publish',
                        component: ConsoleEduAdminClassCourseWorkPublish,
                    },
                    {
                        path: 'eduAdmin/class/course/batch',
                        component: ConsoleEduAdminClassCourseWorkBatch,
                    },
                    {
                        path: 'eduAdmin/class/course/report',
                        component: ConsoleEduAdminClassCourseReport,
                    },
                    {
                        path: 'eduAdmin/class/course/report/batch',
                        component: ConsoleEduAdminClassCourseReportBatch,
                    },
                    {
                        path: 'eduAdmin/student',
                        component: ConsoleEduAdminStudent,
                    },
                    {
                        path: 'class',
                        component: ConsoleClass,
                        meta: {title: "控制台-培训班级"},
                    },
                    {
                        path: 'class/:class_id',
                        component: ConsoleClassDetail,
                    },
                    {
                        path: 'class/:class_id/:course_id',
                        component: ConsoleClassCourse,
                    },
                    {
                        path: 'class/work/:work_id',
                        component: ConsoleClassCourseWork,
                    },
                    {
                        path: 'class/form/:class_id',
                        component: ConsoleClassForm,
                    },
                    {
                        path: 'class/:class_id/homework/correct',
                        name: 'homework/correct',
                        component: ConsoleClassHomeworkCorrect,
                    },
                    {
                        path: 'class/:class_id/homework/finish',
                        name: 'homework/finish',
                        component: ConsoleClassHomeworkFinish,
                    },
                    {
                        path: 'class/:class_id/homework/form',
                        name: 'homework/form',
                        component: ConsoleClassHomeworkForm,
                    },
                    {
                        path: 'class/homework/:work_id',
                        component: ConsoleClassHomeworkDetail,
                    },
                    {
                        path: 'class/wrong_question_set/:work_id',
                        component: ConsoleClassWrongQuestionSetDetail,
                    },
                    {
                        path: 'app',
                        component: ConsoleApp,
                        meta: {title: "控制台-我的应用"},
                        children: [
                            {
                                path: '',
                                component: ConsoleAppEnvironment
                            },
                            {
                                path: 'environment',
                                component: ConsoleAppEnvironment
                            },
                            {
                                path: 'environment/:env_id',
                                component: ConsoleAppEnvironmentDetail
                            },
                            {
                                path: 'template',
                                component: ConsoleAppTemplate
                            },
                            {
                                path: 'namespace',
                                component: ConsoleAppNamespace
                            },
                        ]
                    },
                    {
                        path: 'job',
                        component: ConsoleJob,
                        meta: {title: "控制台-求职就业"},
                    },
                    {
                        path: 'job/form/:job_id',
                        component: ConsoleJobForm,
                    },
                    {
                        path: 'course',
                        meta: {title: "控制台-我的课程"},
                        component: ConsoleCourse,
                    },
                    {
                        path: 'course/create',
                        meta: {title: "控制台-新建课程"},
                        component: ConsoleCourseCreate,
                    },
                    {
                        path: 'course/:course_id',
                        component: ConsoleCourseDetail,
                    },
                    {
                        path: 'course/:course_id/history',
                        component: ConsoleCourseHistory,
                    },
                    {
                        path: 'course/:course_id/demand',
                        meta: {title: "控制台-制作需求"},
                        component: ConsoleCourseDemand,
                    },
                    {
                        path: 'course/:course_id/:chapter_id/:lesson_id/edit',
                        meta: {title: "控制台-课程制作"},
                        component: ConsoleCourseLessonEdit,
                    },
                    {
                        path: 'course/:course_id/:chapter_id/:lesson_id/edit/exercise',
                        meta: {title: "控制台-创建练习"},
                        component: ConsoleCourseLessonEditExercise,
                    },
                    {
                        path: 'image',
                        meta: {title: "控制台-我的镜像"},
                        component: ConsoleImage,
                        children: [
                            {
                                path: '',
                                component: ConsoleImageContainer
                            },
                            {
                                path: 'container',
                                component: ConsoleImageContainer
                            },
                            {
                                path: 'vm',
                                component: ConsoleImageVirtualMachine
                            },
                        ]
                    },
                    {
                        path: 'image/container/:image_id',
                        component: ConsoleImageContainerDetail,
                    },
                    {
                        path: 'image/container/create',
                        component: ConsoleImageContainerCreate,
                    },
                    {
                        path: 'image/container/:image_id/app/create',
                        meta: {title: "控制台-我的镜像-制作应用"},
                        component: ConsoleImageAppCreate,
                    },
                    {
                        path: 'image/container/:image_id/app/:user_app_id',
                        meta: {title: "控制台-我的镜像-应用详情"},
                        component: ConsoleImageAppDetail,
                    },
                    {
                        path: 'image/vm/:vm_image_id',
                        component: ConsoleImageVirtualMachineDetail,
                    },
                    {
                        path: 'image/vm/create',
                        component: ConsoleImageVirtualMachineCreate,
                    },
                    {
                        path: 'image/vm/:vm_image_id/app/create',
                        meta: {title: "控制台-我的镜像-制作应用"},
                        component: ConsoleImageAppCreate,
                    },
                    {
                        path: 'image/vm/:vm_image_id/app/:user_app_id',
                        meta: {title: "控制台-我的镜像-应用详情"},
                        component: ConsoleImageAppDetail,
                    },
                ]
            },
        ]
    },
    {
        // http://127.0.0.1:8080/#/terminal/solution/193/inner_service_name/linux
        path: "/terminal/solution/:solution_id/inner_service_name/:inner_service_name",
        component: Terminal,
        meta: {
            title: '实验环境'
        },
    },
    {
        // http://127.0.0.1:8080/#/terminal/solution/193/inner_service_name/linux
        path: "/terminal2/solution/:solution_id/inner_service_name/:inner_service_name",
        component: Terminal2,
        meta: {
            title: '实验环境'
        },
    },
    {
        path: '/:pathMatch(.*)*', // 通配符路径，匹配所有未定义的路径
        component: NotFound,
    },
    {
        path: "/desktop",
        name: 'desktop',
        meta: {
            title: '桌面控制台首页'
        },
        component: Desktop,
        children: [
            {
                path: '',
                component: Desktop,
                name: Desktop,
                meta: {title: '桌面控制台首页'},
            },
            {
                path: 'home',
                component: Desktop,
                meta: {title: '桌面控制台-首页'},
            },
        ]
    },
    {
        path: "/live/rtc/:live_id",
        component: LiveRtc,
        meta: {title: '我的Live音频通信'},
    },
    {
        path: '/login_desktop',
        component: DesktopComponent,
        meta: {title: '登录'},
    },
]

const router = createRouter({
    // 使用哈希模式创建路由历史
    history: createWebHashHistory(),
    routes, // 配置路由
});

// 路由导航守卫
router.beforeEach((to, from, next) => {
    // 如果路由有 `meta.title` 字段，则设置为页面标题
    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    } 
    // 如果路由匹配失败（未找到匹配项），可以设置默认标题
    else if (to.matched.length === 0) {
        document.title = '404 - 页面未找到';
    } 
    // 如果没有任何标题设置，则清空标题
    else {
        document.title = '';
    }

    // 继续导航
    next();
});

export default router
